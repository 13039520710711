import React, { FC } from 'react';
import classnames from 'classnames';

import { flexRender, Table } from '@tanstack/react-table';
import { Icon } from '../../Icon';

interface Props {
  table: Table<Record<string, unknown>>;
  thClassName?: string;
  flexRender: typeof flexRender;
  sortable?: boolean;
}

export const TableHead: FC<Props> = ({ table, thClassName, flexRender, sortable = false }) => {
  return (
    <thead className="w-full text-xs text-white bg-internationalBlue">
      {table?.getHeaderGroups()?.map((headerGroup) => (
        <tr className="w-full" key={headerGroup.id}>
          {headerGroup?.headers?.map((header) => {
            const { column } = header;
            const canSort = column.getCanSort();
            const isSorted = column.getIsSorted();
            const enableSorting = column.columnDef.enableSorting && sortable;
            const sortingHandler = canSort && enableSorting ? column.getToggleSortingHandler() : undefined;

            return (
              <th
                className={classnames('text-start py-5 px-1 first:pl-4', thClassName, {
                  'cursor-pointer': canSort && enableSorting
                })}
                key={header.id}
                colSpan={header.colSpan}
                onClick={sortingHandler}
              >
                {!header.isPlaceholder && (
                  <div className="flex items-center">
                    {flexRender(column.columnDef.header, header.getContext())}

                    {canSort && enableSorting && isSorted && (
                      <div className="ml-1">
                        <Icon
                          type={isSorted === 'asc' ? 'chevronUp' : 'chevronDown'}
                          sizeClassName="text-xs"
                          appearance="white"
                        />
                      </div>
                    )}
                    {/* Top secret experimental sortable colums indicators */}
                    {/* {canSort && enableSorting && (
                      <div className="flex flex-col ml-1">
                        <Icon
                          type={'chevronUp'}
                          sizeClassName="text-xs leading-3"
                          appearance={isSorted === 'asc' ? 'white' : 'highlightingBlue'}
                        />

                        <Icon
                          type={'chevronDown'}
                          sizeClassName="text-xs leading-3"
                          appearance={isSorted === 'desc' ? 'white' : 'highlightingBlue'}
                        />
                      </div>
                    )} */}
                  </div>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
