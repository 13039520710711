import * as yup from 'yup';
import set from 'lodash/set';
import type { Resolver } from 'react-hook-form';

export default function customResolver<S extends yup.AnyObjectSchema>(schema: S): Resolver<yup.InferType<S>> {
  return async (values) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return { values, errors: {} };
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = error.inner.reduce<Record<string, any>>((allErrors, { path, type, message, params }) => {
          if (!path) return allErrors;

          return set(allErrors, path, { type, message, params });
        }, {});

        return { values: {}, errors };
      }
      // Handle unexpected errors not related to validation
      return { values: {}, errors: { unexpected: { message: 'An unexpected error occurred' } } };
    }
  };
}
