import React from 'react';

import { translations } from '@/locales';
import { useIntl } from 'react-intl';
import { ClientStatus } from '@/domains';
import { SimpleCombobox, SimpleComboboxProps } from '@nshift/common/components/Select/SimpleCombobox';

export interface Props extends Omit<SimpleComboboxProps, 'items' | 'label' | 'name'> {
  value: string[];
  onChange?: (value: string[]) => void;
}

export const clientStatusToValueMap: Record<ClientStatus, any> = {
  [ClientStatus.Connected]: 'connected',
  [ClientStatus.Disconnected]: 'disconnected',
  [ClientStatus.OutOfPaper]: 'outOfPaper'
};

export const clientStatusToNameMap: Record<ClientStatus, string> = {
  [ClientStatus.Connected]: translations.domains.clientStatusOptions.CONNECTED,
  [ClientStatus.Disconnected]: translations.domains.clientStatusOptions.DISCONNECTED,
  [ClientStatus.OutOfPaper]: translations.domains.clientStatusOptions.OUT_OF_PAPER
};

export const ClientStatusSelect: React.FC<Props> = ({ value, onChange, ...props }) => {
  const intl = useIntl();

  const statusOptions = Object.values(ClientStatus).map((status) => ({
    id: clientStatusToValueMap[status],
    name: intl.formatMessage({ id: clientStatusToNameMap[status] })
  }));

  return (
    <SimpleCombobox
      {...props}
      name="clientStatus"
      value={value || []}
      items={statusOptions}
      label={intl.formatMessage({ id: translations.pages.clientSetup.columnHeaders.status })}
      onChange={onChange}
      selectionMode="multiple"
    />
  );
};
