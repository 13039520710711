import React from 'react';
import {
  Route,
  Navigate,
  Outlet,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';

import { urls } from '@/constants';

import { Header, Footer } from '@/components/App';
import { ClientSetupPage } from '@/pages/Client';
import PrintJobsPage from '@/pages/PrintJobs/PrintJobsPage';
import { AuthBasePage } from '@/pages/AuthPages/AuthBasePage';
import { ActivatePage } from '@/pages/AuthPages/ActivatePage';
import { PrinterProfileDetailPage } from '@/pages/PrinterProfiles';
import PrinterProfilesPage from '@/pages/PrinterProfiles/PrinterProfilesPage';
import { ResetPasswordPage, SignInPage, ForgotPasswordPage } from '@/pages/AuthPages';
import { PageNotFoundPrint } from './PageNotFoundPrint';

import { Session } from '@/containers/SessionContainer';
import { PrintErrorFallback } from '../PrintErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

export const AppContent: React.FC = () => {
  const { authenticated } = Session.useContainer();

  const isProduction = process.env.NODE_ENV !== 'development';

  const authRoutes = [urls.signIn, urls.activate, urls.forgotPassword, urls.resetPassword.main];

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {authenticated ? (
          <Route element={<AuthenticatedPage />}>
            {!isProduction && <Route path={urls.setUp.clients} element={<ClientSetupPage />} />}
            {!isProduction && <Route path={urls.printJobs.base} element={<PrintJobsPage />} />}
            <Route path={urls.printers.profiles.base} element={<PrinterProfilesPage />} />
            <Route path={urls.printers.profiles.create} element={<PrinterProfileDetailPage isEdit={false} />} />
            <Route path={urls.printers.profiles.detail} element={<PrinterProfileDetailPage isEdit />} />
            <Route path={urls.home} element={<Navigate to={urls.printers.profiles.base} />} />
            {authRoutes.map((route) => (
              <Route key={route} path={route} element={<Navigate to={urls.home} />} />
            ))}
          </Route>
        ) : (
          <Route element={<AuthBasePage />}>
            <Route path={urls.signIn} element={<SignInPage />} />

            <Route path={urls.activate} element={<ActivatePage />} />

            <Route path={urls.forgotPassword} element={<ForgotPasswordPage />} />

            <Route path={urls.resetPassword.main} element={<ResetPasswordPage />} />

            <Route path="*" element={<Navigate to={urls.signIn} />} />
          </Route>
        )}

        <Route path="*" element={<PageNotFoundPrint />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export const AuthenticatedPage: React.FC = () => {
  return (
    <>
      <Header />
      <div className="relative flex w-full pt-20 mx-auto bg-darkBlue-5 min-h-fit max-w-7xl">
        <ErrorBoundary fallback={<PrintErrorFallback hasTryAgainButton />}>
          <Outlet />
        </ErrorBoundary>
      </div>
      <Footer />
    </>
  );
};
