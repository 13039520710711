import classNames from 'classnames';
import React, { FC } from 'react';

export const RequiredTag: FC = () => {
  return <span className="ml-px text-warningRed">*</span>;
};

interface Props {
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export const RequiredLabel: FC<Props> = ({ label, isRequired, isDisabled }) => (
  <span>
    {label}
    {isRequired && <span className={classNames('ml-px', isDisabled ? 'text-darkBlue-40' : 'text-warningRed')}>*</span>}
  </span>
);
