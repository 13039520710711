import * as React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { translations } from '@/locales';
import { truncate } from '@/util/truncate';
import { DialogOpenFn, urls } from '@/constants';
import { TableConfig } from '@nshift/common/components/Table';

import { PrinterProfileStatusBadge } from './PrinterProfileStatusBadge';
import { PrinterProfileDialogs, PrinterProfileStatus } from './types';

export const getStatus = (row: Record<string, unknown>) => {
  return row.active ? PrinterProfileStatus.Active : PrinterProfileStatus.Inactive;
};

type PrinterProfilesTableHook = (
  params?: { onDialogOpen?: DialogOpenFn<PrinterProfileDialogs> } | undefined
) => TableConfig;

export const usePrinterProfileConfig: PrinterProfilesTableHook = ({ onDialogOpen } = {}) => {
  const intl = useIntl();

  const navigate = useNavigate();

  console.log({ onDialogOpen });
  const rowActionButtons: any[] = [
    {
      id: 'edit',
      label: intl.formatMessage({ id: translations.application.buttons.edit }),
      handlerFn: (row: any) => {
        navigate(urls.printers.getDetailUrl(row.toString()));
      }
    },
    {
      id: 'delete',
      label: intl.formatMessage({ id: translations.application.buttons.delete }),
      shouldCloseDrawer: true,
      handlerFn: (row: any) => {
        console.log('delete', row);
        onDialogOpen?.({ name: PrinterProfileDialogs.Delete, rowIds: [row] });
      }
    }
  ];

  const colsConfig: any[] = [
    {
      accessorKey: 'id',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.id }),
      enableSorting: true
    },
    {
      accessorKey: 'name',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.name }),
      enableSorting: true,
      cell: (info: any) => <div>{truncate(info.getValue(), 40)}</div>
    },
    {
      accessorKey: 'active',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.status }),
      cell: (info: any) => <PrinterProfileStatusBadge status={getStatus(info.row.original)} />,
      enableSorting: true
    },
    {
      accessorKey: 'clientName',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.client }),
      cell: (info: any) => <div>{truncate(info.getValue(), 40)}</div>,
      enableSorting: true
    },
    {
      accessorKey: 'printerProfileGroups',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.profileGroups }),
      cell: (info: any) => {
        const groupNamesList = info.getValue().map(({ name }: { name: string }) => name);

        return <div>{truncate(groupNamesList.join(', '), 30)}</div>;
      }
    },
    {
      accessorKey: 'printerName',
      header: intl.formatMessage({ id: translations.domains.commonColumnsFilters.printer }),
      cell: (info: any) => <div>{truncate(info.getValue(), 40)}</div>,
      enableSorting: true
    },
    {
      accessorKey: 'format',
      header: intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.format }),
      enableSorting: true
    },
    {
      accessorKey: 'media',
      header: intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.media }),
      cell: (info: any) => <div>{truncate(info.getValue(), 40)}</div>,
      enableSorting: true
    }
  ];

  return {
    colsConfig,
    rowActionButtons
  };
};
