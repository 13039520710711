import * as React from 'react';

import { PrinterProfileStatus } from './types';
import { Badge, Appearance } from '@nshift/common/components/Badge';
import { FormattedMessage } from 'react-intl';
import { translations } from '@/locales';
import classNames from 'classnames';

const statusColorMapping: Record<PrinterProfileStatus, Appearance> = {
  [PrinterProfileStatus.Active]: 'green',
  [PrinterProfileStatus.Inactive]: 'gray'
};

interface Props {
  status: PrinterProfileStatus;
  className?: string;
}

export const PrinterProfileStatusBadge: React.FC<Props> = ({ status, className }) => {
  return (
    <Badge className={classNames('text-xs flex justify-center', className)} appearance={statusColorMapping[status]}>
      <FormattedMessage id={translations.domains.printerProfilesStatusFilter[status]} />
    </Badge>
  );
};
