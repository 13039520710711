import React, { useState } from 'react';
import { BaseTable } from './BaseTable';
import { TableFooter } from './TableFooter';
import { isNil } from 'lodash';
import { TableRowDetailsDrawer } from './TableRowDetailsDrawer';
import { TableBulkActionsBar } from './TableBulkActionsBar';
import { Props } from './Table';
import { checkboxColumn, rowActionsColumn } from './constants';

export const SortableTable: React.FC<Props> = ({
  id,
  columns,
  rowCount,
  rowIdProperty = 'id',
  bulkActionButtons,
  drawerActionButtons,
  rowActionButtons,
  defaultSelectedPage,
  renderRowDetailsSection,
  onPageChange,
  onPageSizeChange,
  data = [],
  pageHasHeader = true,
  noResultsTitle,
  noResultsMessage,
  loading,
  ...rest
}) => {
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [clickedRow, setClickedRow] = useState<Record<string, unknown>>();
  const tableRef = React.useRef<any>();

  const handlePageSizeChange = (size: number) => {
    onPageSizeChange?.(size);
  };

  const handleSelectedRowIdsChange = (tableRowIndexes: string[]) => {
    const entityIds = data
      .filter((row: Record<string, unknown>) => {
        const index = data.indexOf(row);
        return tableRowIndexes.includes(index.toString());
      })
      .map((row: Record<string, unknown>) => {
        return row[rowIdProperty];
      });
    setSelectedRowIds(entityIds);
  };

  return (
    <div id={id}>
      <BaseTable
        {...{
          ...rest,
          data,
          bulkActionButtons,
          rowCount,
          loading,
          noResultsMessage,
          noResultsTitle,
          columns: [
            bulkActionButtons?.length > 0 ? checkboxColumn : null,
            ...columns,
            rowActionButtons?.length > 0 ? rowActionsColumn(rowActionButtons, setClickedRow) : null
          ].filter(Boolean)
        }}
        ref={tableRef}
        onSelectionChange={handleSelectedRowIdsChange}
        onRowClick={setClickedRow}
        thClassName="uppercase"
        sortable={true}
      />
      {!isNil(rowCount) && !loading && data?.length > 0 && (
        <TableFooter rowCount={rowCount} onPageSizeChange={handlePageSizeChange} />
      )}
      {!isNil(clickedRow) && !isNil(renderRowDetailsSection) && (
        <TableRowDetailsDrawer
          row={clickedRow.id}
          actionButtons={drawerActionButtons}
          onClose={() => {
            setClickedRow(undefined);
          }}
          className={!pageHasHeader ? '!top-0' : ''}
        >
          {renderRowDetailsSection(clickedRow)}
        </TableRowDetailsDrawer>
      )}
      {!isNil(bulkActionButtons) && bulkActionButtons.length > 0 && selectedRowIds?.length > 0 && (
        <TableBulkActionsBar
          bulkActionButtons={bulkActionButtons}
          selectedRowIds={selectedRowIds}
          onClose={() => {
            tableRef.current.clearRowSelection();
          }}
        />
      )}
    </div>
  );
};
