import React from 'react';
import { useQuery } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';

import { translations } from '@/locales';
import { urls } from '@/constants';
import { getPrinterProfileDetail } from '@/services/api';
import { printerProfileToFormData } from '@/util/adapters';

import { LoadingSize, Spinner, SpinnerColor } from '@nshift/common/components/Loading';

import { PrinterProfileForm } from './PrinterProfileForm';
import { PrintErrorFallback } from '@/components/PrintErrorFallback';

import { InitialPrinterProfileFormData } from './types';

const errorBoundary = translations.application.errorBoundary;

export const PrinterProfileDetailPage: React.FC<{ isEdit: boolean }> = ({ isEdit }) => {
  const intl = useIntl();

  return (
    <div className="w-full max-w-4xl py-20 mx-auto">
      <div className="mb-8 text-3xl font-semibold font-title">
        <FormattedMessage
          id={isEdit ? translations.pages.printerProfiles.edit.title : translations.pages.printerProfiles.create.title}
        />
      </div>
      <ErrorBoundary
        fallback={
          <PrintErrorFallback
            buttonNavigationLink={urls.printers.profiles.base}
            buttonText={intl.formatMessage({ id: errorBoundary.buttonText })}
            hasTryAgainButton
          />
        }
      >
        <PrinterProfileDetailPageContent {...{ isEdit }} />
      </ErrorBoundary>
    </div>
  );
};

const PrinterProfileDetailPageContent: React.FC<{ isEdit: boolean }> = ({ isEdit }) => {
  const { id } = useParams<{ id: string }>();
  const { showBoundary } = useErrorBoundary();

  const { isLoading, data } = useQuery<InitialPrinterProfileFormData>(
    'get-printer-profile',
    () =>
      getPrinterProfileDetail(Number(id))
        .then(printerProfileToFormData)
        .catch((error) => {
          showBoundary(error);
          return error;
        }),
    {
      enabled: isEdit,
      cacheTime: 0
    }
  );

  return (
    <div className="flex flex-col">
      {isLoading ? (
        <div className="flex items-center justify-center p-6 mx-6">
          <Spinner isIndeterminate size={LoadingSize.Medium} fillColor={SpinnerColor.Primary} />
        </div>
      ) : (
        <PrinterProfileForm defaultData={isEdit ? data : {}} key={id} editMode={isEdit} profileId={id ?? '0'} />
      )}
    </div>
  );
};
