import React from 'react';

import { BaseSelect, type BaseSelectProps } from './BaseSelect';

import { Control, useController } from 'react-hook-form';

import { type OmitableFieldTypes } from '@nshift/common/types/ommitableFieldTypes';

interface FormSelectProps extends Omit<BaseSelectProps, OmitableFieldTypes> {
  control: Control<any>;
}

export const FormSelect: React.FC<FormSelectProps> = ({ name, control, ...rest }) => {
  const { field, fieldState } = useController({ name, control });

  return <BaseSelect {...rest} {...field} isInvalid={fieldState.invalid} error={fieldState.error} />;
};
