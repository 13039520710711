import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import type { ComboBoxProps, Key, ListBoxItemProps } from 'react-aria-components';
import { Button, ComboBox, Group, Input, Label, ListBox, ListBoxItem, Popover } from 'react-aria-components';

import { Icon } from '@nshift/common/components/Icon';
import { FieldError } from '@nshift/common/components/FieldError';
import { RequiredTag } from '@nshift/common/components/RequiredTag';
import { Control, FieldValues, useController } from 'react-hook-form';
import { type OmitableFieldTypes } from '@nshift/common/types/ommitableFieldTypes';
import { labelStyle, textInputStyle } from '@nshift/common/components/Select/TextInput';

export interface ComboboxWithProblematicChoices extends ComboBoxProps<any> {
  label?: string;
  items?: { id: string; value: string }[];
  problematicItems?: { id: string; value: string }[];
  value: string;
  onChange: (event: unknown) => void;
  children?: React.ReactNode;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  warning?: string;
  error?: any;
}

export const ComboboxWithProblematicChoices: React.FC<ComboboxWithProblematicChoices> = ({
  label,
  items,
  problematicItems,
  value,
  onChange,
  error,
  children,
  required,
  warning,
  ...props
}) => {
  const choices: { id: string; value: string; isProblematic?: boolean }[] = useMemo(() => {
    return (
      items?.concat(
        problematicItems?.map((item) => ({
          ...item,
          isProblematic: true
        })) || []
      ) || []
    );
  }, [items, problematicItems]);
  const [fieldState, setFieldState] = React.useState({
    selectedKey: (value?.[0] as Key) ?? undefined,
    inputValue: choices?.find((item) => item.id === value?.[0])?.value ?? ''
  });

  const onSelectionChange = (id?: Key) => {
    onChange(id ? [id] : undefined);
    setFieldState({
      inputValue: choices?.find((item) => item.id === id)?.value ?? '',
      selectedKey: id ? id : undefined
    });
  };

  const onInputChange = (value: string) => {
    setFieldState((prevState) => ({
      inputValue: value,
      selectedKey: value === '' ? undefined : prevState.selectedKey
    }));
  };

  return (
    <ComboBox
      isDisabled={props.disabled}
      selectedKey={fieldState.selectedKey}
      inputValue={fieldState.inputValue}
      onInputChange={onInputChange}
      onSelectionChange={onSelectionChange}
      menuTrigger="focus"
      className="flex flex-col w-full gap-1"
      {...props}
    >
      <Group className="relative w-full group">
        {({ isDisabled, isFocusWithin }) => (
          <>
            <Label
              className={labelStyle({
                focused: isFocusWithin || !isNil(value),
                invalid: !isNil(error),
                disabled: isDisabled
              })}
            >
              {label}
              {required && <RequiredTag />}
            </Label>

            <div className="relative flex w-full">
              <Input className={textInputStyle({ withTrailingIcon: true, className: classNames('pr-20') })} />

              <div className="absolute inset-y-0 flex items-center justify-end ml-auto right-2">
                <Button
                  onPress={() => onSelectionChange()}
                  className="flex justify-center pr-2 mr-2 border-r cursor-pointer border-r-darkBlue-30"
                >
                  <Icon type="close" sizeClassName="text-md" aria-hidden="true" appearance="blue" />
                </Button>

                <Button className="flex items-center justify-center">
                  <Icon type="chevronDownAlt" sizeClassName="text-md" aria-hidden="true" appearance="blue" />
                </Button>
              </div>
            </div>
            {problematicItems?.map((item) => item.id).includes(String(fieldState.selectedKey)) && (
              <div className="text-xs text-alertingYellow-STRONG">{warning}</div>
            )}
            <FieldError error={error} pathOverride={label} />
          </>
        )}
      </Group>

      <Popover className="w-[--trigger-width] flex z-10 shadow border-darkBlue-5 bg-white rounded-md entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out">
        <ListBox className="w-full p-2 space-y-1 overflow-y-auto outline-none max-h-60">
          {children}

          {choices?.map((item) => (
            <SelectItem
              textValue={item.value}
              key={item.id}
              id={item.id}
              aria-label={item.value}
              isProblematic={item.isProblematic}
            >
              {item.value}
            </SelectItem>
          ))}
        </ListBox>
      </Popover>
    </ComboBox>
  );
};

export const SelectItem: React.FC<React.PropsWithChildren<ListBoxItemProps> & { isProblematic?: boolean }> = ({
  children,
  isProblematic,
  ...props
}) => (
  <ListBoxItem
    {...props}
    className={({ isSelected, isPressed, isFocusVisible, isFocused, isHovered, isDisabled }) =>
      classNames(
        'flex items-center flex-1 gap-2 truncate cursor-pointer rounded text-sm h-8 px-2',
        isDisabled ? 'text-darkBlue-40' : 'text-darkBlue-70',
        isHovered && 'bg-highlightingBlue-5',
        isPressed && 'pressed',
        isFocusVisible && 'focus-visible',
        isSelected && 'bg-highlightingBlue-30',
        isFocused && 'bg-highlightingBlue-5 focus:outline-highlightingBlue',
        isProblematic && '!text-alertingYellow-STRONG'
      )
    }
  >
    {({ isSelected }) => (
      <>
        {children}
        {isSelected && <Icon type="checkmark" size="small" appearance="blue" className="ml-auto" />}
      </>
    )}
  </ListBoxItem>
);

interface FormComboboxWithProblematicChoicesProps extends Omit<ComboboxWithProblematicChoices, OmitableFieldTypes> {
  control: Control<FieldValues>;
}

export const FormComboboxWithProblematicChoices: React.FC<FormComboboxWithProblematicChoicesProps> = ({
  name,
  control,
  required,
  ...rest
}) => {
  const { field, fieldState } = useController({ name, control });

  return <ComboboxWithProblematicChoices required={required} error={fieldState.error} {...rest} {...field} />;
};
