import React from 'react';
import { Control, useController } from 'react-hook-form';
import { TextInput } from '@nshift/common/components/Select/TextInput';

interface FormTextFieldProps extends React.ComponentProps<typeof TextInput> {
  control: Control<any>;
}

export const FormTextInput: React.FC<FormTextFieldProps> = ({ name, control, ...rest }) => {
  const { field, fieldState } = useController({ name, control });

  return (
    <TextInput
      error={fieldState.error}
      isInvalid={fieldState.invalid}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      {...{ ...rest }}
    />
  );
};
