import React, { FC } from 'react';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../Icon';
import { noop } from 'lodash';
import { useErrorBoundary } from 'react-error-boundary';

interface Props {
  buttonText?: string;
  buttonNavigationLink?: string;
  title: string;
  message?: string;
  errorMessage?: string;
  onButtonClick?: () => void;
  tryAgainButtonText?: string;
  hasTryAgainButton?: boolean;
}

export const GenericError: FC<Props> = ({
  buttonText,
  title,
  message,
  errorMessage,
  buttonNavigationLink,
  onButtonClick = noop,
  tryAgainButtonText,
  hasTryAgainButton = false
}) => {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  const handleOnClick = () => {
    resetBoundary();
    if (buttonNavigationLink) {
      navigate(buttonNavigationLink);
    }
    onButtonClick?.();
  };

  return (
    <div className="py-20 w-full max-w-xl mx-auto">
      <div className="flex flex-col mx-6 py-6 bg-white rounded-lg shadow">
        <div className="text-lg font-semibold mx-6 mb-2 border-b border-darkBlue-20 pb-4">
          <Icon type="error" appearance="red" size="medium" className="mr-4" />
          {title}
        </div>
        <div className="text-base text-darkBlue-50 mx-8 py-2">
          <div className="mb-14 flex flex-col gap-4">
            {message && <div>{message}</div>}
            {!!errorMessage && <div>{errorMessage}</div>}
          </div>
          <div className="flex justify-end gap-2">
            {buttonText && (
              <Button onClick={handleOnClick} appearance={hasTryAgainButton ? 'secondary' : 'primary'}>
                {buttonText}
              </Button>
            )}
            {hasTryAgainButton && (
              <Button
                onClick={() => {
                  resetBoundary();
                }}
                appearance="primary"
              >
                {tryAgainButtonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
