import React from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import {
  Button,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  ListBoxItemProps,
  SelectProps,
  Key,
  Label
} from 'react-aria-components';

import { Icon } from '@nshift/common/components/Icon/IconNew';
import { FieldError } from '@nshift/common/components/FieldError';
import { RequiredLabel } from '@nshift/common/components/RequiredTag';

import { labelStyle, textInputStyle } from './TextInput';

export interface BaseSelectProps extends SelectProps<any> {
  label?: string;
  items?: { id: string; value: string }[];
  value: string;
  onChange: (key: Key) => void;
  children?: React.ReactNode;
  className?: string;
  error?: any;
}

export const BaseSelect: React.FC<BaseSelectProps> = ({
  label,
  children,
  items,
  onChange,
  value,
  className,
  error,
  ...props
}) => (
  <Select
    {...props}
    aria-label={props.name}
    className={classNames('flex flex-col gap-1 w-full', className)}
    selectedKey={value}
    onSelectionChange={onChange}
  >
    {({ isDisabled, isFocused, isRequired }) => (
      <>
        <Button className="relative focus:outline-none group focus-visible:ring-0">
          <div
            className={textInputStyle({
              withTrailingIcon: true,
              className:
                isDisabled &&
                'bg-darkBlue-5 group-hover:border-darkBlue-40 cursor-not-allowed text-darkBlue-40 group-hover:bg-darkBlue-5'
            })}
          >
            {items.find((item) => item.id === value)?.value}
          </div>

          <FieldError error={error} pathOverride={label} />

          <Label
            className={labelStyle({ focused: isFocused || !isNil(value), disabled: isDisabled, invalid: !!error })}
          >
            <RequiredLabel {...{ isRequired, isDisabled, label }} />
          </Label>

          <Icon type="chevronDownAlt" size="small" className="absolute right-3 top-2.5" light={isDisabled} />
        </Button>

        <Popover className="w-[--trigger-width] flex z-10 shadow border-darkBlue-5 bg-white rounded-md mt-1 entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out">
          <ListBox className="w-full p-2 space-y-1 overflow-y-auto outline-none max-h-60">
            {children}

            {items.map((item) => (
              <SelectItem key={item.id} id={item.id} aria-label={item.value}>
                {item.value}
              </SelectItem>
            ))}
          </ListBox>
        </Popover>
      </>
    )}
  </Select>
);

export const SelectItem: React.FC<React.PropsWithChildren<ListBoxItemProps>> = ({ children, ...props }) => (
  <ListBoxItem
    {...props}
    className={({ isSelected, isPressed, isFocusVisible, isFocused, isHovered, isDisabled }) =>
      classNames(
        'flex items-center flex-1 gap-2 truncate cursor-pointer rounded text-sm h-8 px-2',
        isDisabled ? 'text-darkBlue-40' : 'text-darkBlue-70',
        isHovered && 'bg-highlightingBlue-5',
        isPressed && 'pressed',
        isFocusVisible && 'focus-visible',
        isSelected && 'bg-highlightingBlue-30',
        isFocused && 'bg-highlightingBlue-5 focus:outline-highlightingBlue'
      )
    }
  >
    {children}
  </ListBoxItem>
);
