import * as React from 'react';
import { FC } from 'react';
import { noop } from 'lodash';
import { TablePagination } from './TablePagination';
import { tablePageSizeOptions } from './constants';
import { BaseSelect } from '../Select/BaseSelect';

interface Props {
  selectedPage?: number;
  rowCount?: number;
  pageSize?: number;
  hideInformation?: boolean;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (page: number) => void;
}
export const TableFooter: FC<Props> = ({
  selectedPage = 1,
  rowCount = 0,
  pageSize = 5,
  hideInformation = false,
  onPageChange = noop,
  onPageSizeChange = noop
}) => {
  const pagination = (
    <TablePagination
      currentPage={selectedPage}
      pageCount={Math.ceil(rowCount / pageSize)}
      onPageChange={onPageChange}
    />
  );

  const handlePageSelection = (value: string) => {
    if (value) {
      onPageSizeChange(Number(value ?? '5'));
    }
  };

  return (
    <div className="mt-5 px-5">
      {hideInformation ? (
        pagination
      ) : (
        <div className="grid grid-cols-3">
          <BaseSelect
            value={pageSize.toString() ?? '5'}
            onSelectionChange={handlePageSelection}
            items={tablePageSizeOptions.filter(({ id }) => Number(id) <= rowCount)}
            onChange={handlePageSelection}
            className="max-w-[55px]"
          />
          {pagination}
          <div className="flex justify-end items-center text-darkBlue-70">
            {(selectedPage - 1) * pageSize + 1} - {selectedPage * pageSize} of {rowCount}
          </div>
        </div>
      )}
    </div>
  );
};