import classNames from 'classnames';

export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export const sizeToStyleMap: Record<
  Size,
  {
    inputStyle: string;
    descriptionStyle: string;
    placeholderStyle: { focused: string; unfocused: string };
  }
> = {
  [Size.Small]: {
    inputStyle: 'rounded-md h-8 text-xs',
    placeholderStyle: {
      focused: 'text-xs text-darkBlue-40 mx-1 -top-2 left-1.5 px-1',
      unfocused: 'text-xs text-darkBlue-50 top-2 left-3.5'
    },
    descriptionStyle: 'text-xs'
  },
  [Size.Medium]: {
    inputStyle: 'rounded-lg h-10 text-sm',
    placeholderStyle: {
      focused: 'text-xs text-darkBlue-40 mx-1 -top-2 left-1.5 px-1',
      unfocused: 'text-sm text-darkBlue-50 top-2.5 left-3.5'
    },
    descriptionStyle: 'text-sm'
  },
  [Size.Large]: {
    inputStyle: 'rounded-lg h-12 text-base',
    placeholderStyle: {
      focused: 'text-sm text-darkBlue-40 mx-1 -top-2.5 left-1.5 px-1',
      unfocused: 'text-base text-darkBlue-50 top-3 left-3.5'
    },
    descriptionStyle: 'text-lg'
  }
};

export const labelHalfFillBackground = (isDisabled: boolean) => {
  return classNames(
    isDisabled ? 'before:bg-internationalBlue-3' : 'before:bg-white group-hover:before:bg-internationalBlue-5',
    'before:absolute before:h-1/2 before:-z-10 before:left-0 before:bottom-0',
    'before:transition-colors before:duration-200',
    'before:overflow-hidden before:w-full'
  );
};

export const inputStyling = (isDisabled: boolean, isInvalid: boolean) => {
  return classNames(
    'text-darkBlue-70 min-w-7',
    'px-3.5 outline-none border border-darkBlue-30',
    isDisabled
      ? 'cursor-not-allowed bg-internationalBlue-3'
      : 'group-hover:border-internationalBlue group-hover:bg-internationalBlue-5',
    'focus:border-internationalBlue',
    'disabled:border-darkBlue-20 disabled:bg-internationalBlue-3',
    'transition-colors duration-200',
    isInvalid && 'border-warningRed'
  );
};

export const transformBetweenPlaceholderAndLabel = (
  hasPlaceholder: boolean,
  isDisabled: boolean,
  size: Size,
  isFocused: boolean
) => {
  if (hasPlaceholder) {
    return classNames(
      'text-darkBlue-60',
      labelHalfFillBackground(isDisabled),
      sizeToStyleMap[size].placeholderStyle['focused'] // When it has a placeholder, it should function as if it's focused
    );
  } else {
    const placeholderKey = isFocused ? 'focused' : 'unfocused';
    return classNames(
      'text-darkBlue-60',
      isFocused && labelHalfFillBackground(isDisabled),
      sizeToStyleMap[size].placeholderStyle[placeholderKey]
    );
  }
};
