import React, { FC } from 'react';
import notFoundSVG from '@nshift/common/assets/notFound.svg';
import { Button } from '../Button';
import logo from '@nshift/common/assets/logoWhite.svg';

interface Props {
  buttonText;
  buttonNavigationLink: string;
  title: string;
  subtitle: string;
}

export const PageNotFound: FC<Props> = ({ buttonText, buttonNavigationLink, title, subtitle }) => {
  return (
    <div className="fixed flex w-full h-full text-white bg-gradient-to-b from-nordicGreen to-internationalBlue">
      <img src={logo} className="absolute h-8 top-16 left-16" />

      <div className="flex m-auto">
        <div className="flex flex-col">
          <div className="text-[270px] leading-[170px] mb-20 font-title font-bold">{title}</div>

          <div className="text-2xl text-wrap max-w-[480px] mb-9">{subtitle}</div>

          <Button className="px-6 w-fit" appearance="secondary" linkTo={buttonNavigationLink}>
            {buttonText}
          </Button>
        </div>

        <img className="w-[334px] ml-10 hidden lg:block xl:w-[534px] xl:ml-[170px]" src={notFoundSVG} />
      </div>
    </div>
  );
};
