import React, { FC } from 'react';
import notFoundSVG from '@nshift/common/assets/notFound.svg';
import { Button } from '../Button';
import logo from '@nshift/common/assets/logoWhite.svg';

interface Props {
  title: string;
  subtitle: string;
}

export const AppOutOfService: FC<Props> = ({ title, subtitle }) => {
  return (
    <div className="flex fixed bg-gradient-to-b from-nordicGreen to-internationalBlue w-full h-full text-white">
      <img src={logo} className="h-8 absolute top-[65px] left-[68px]" />
      <div className="m-auto flex">
        <div className="flex flex-col">
          <div className="text-5xl leading-[70px] mb-20 font-title font-bold">{title}</div>
          <div className="text-2xl text-wrap max-w-[480px] mb-9">{subtitle}</div>
          <Button className="w-fit px-6" appearance="secondary" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
        <img className="w-[334px] hidden lg:block xl:w-[534px]" src={notFoundSVG} />
      </div>
    </div>
  );
};
