import React from 'react';
import { isNil } from 'lodash';
import { tv } from 'tailwind-variants';
import classNames from 'classnames';

import { Button as AriaButton, ButtonProps as AriaButtonProps, ButtonRenderProps } from 'react-aria-components';

import { Icon, Type, IconAppearance } from './Icon/IconNew';

export type Appearance = 'filled' | 'outlined' | 'ghost';

export const iconAppearance: Record<Appearance, { normal: IconAppearance; pressed: IconAppearance }> = {
  filled: { normal: 'white', pressed: 'white' },
  outlined: { normal: 'highlightingBlue', pressed: 'white' },
  ghost: { normal: 'highlightingBlue', pressed: 'white' }
};

export type Size = 'xsmall' | 'standard' | 'large';

const button = tv({
  base: 'group relative inline-flex outline-none items-center disabled:cursor-default text-center transition-colors cursor-pointer box-border text-nowrap',
  variants: {
    appearance: {
      filled:
        'bg-highlightingBlue hover:bg-highlightingBlue-80 data-[pressed]:bg-darkBlue text-white disabled:bg-darkBlue-5 disabled:text-darkBlue-50',
      outlined:
        'bg-white hover:bg-highlightingBlue-20 data-[pressed]:bg-darkBlue border border-highlightingBlue data-[pressed]:text-white data-[pressed]:border-darkBlue text-highlightingBlue disabled:border-darkBlue-50 disabled:bg-darkBlue-5 disabled:text-darkBlue-50',
      ghost:
        'bg-transparent hover:bg-highlightingBlue-20 data-[pressed]:bg-darkBlue data-[pressed]:text-white text-highlightingBlue disabled:bg-white disabled:text-darkBlue-50'
    },
    size: {
      xsmall: 'h-6 rounded px-3',
      standard: 'h-10 rounded-md px-8',
      large: 'h-12 rounded-lg px-8'
    }
  }
});

interface Props extends Omit<AriaButtonProps, 'onPress'> {
  className?: string;
  children?: React.ReactNode | ((props: ButtonRenderProps) => React.ReactNode);
  onClick?: () => void;
  appearance?: Appearance;
  size?: Size;
  leadingIcon?: Type;
  trailingIcon?: Type;
  endIcon?: boolean;
  isLoading?: boolean;
}

export const Button: React.FC<Props> = ({
  size = 'standard',
  appearance = 'filled',
  className,
  onClick,
  children,
  leadingIcon,
  trailingIcon,
  type = 'button',
  ...rest
}) => {
  return (
    <AriaButton
      onPress={onClick}
      className={classNames(button({ appearance, size, className: className }))}
      {...{ ...rest, type }}
    >
      {(props) => (
        <>
          {!isNil(leadingIcon) && (
            <Icon
              type={leadingIcon}
              size="xsmall"
              className="mr-1"
              appearance={props.isPressed ? iconAppearance[appearance].pressed : iconAppearance[appearance].normal}
            />
          )}

          {children}

          {!isNil(trailingIcon) && (
            <Icon
              type={trailingIcon}
              size="xsmall"
              className="ml-1"
              appearance={props.isPressed ? iconAppearance[appearance].pressed : iconAppearance[appearance].normal}
            />
          )}
        </>
      )}
    </AriaButton>
  );
};
