import React from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { resetPassword } from '@/services/api';
import { STRING_LONG_MAX_LENGTH, urls } from '@/constants';

import { Button } from '@nshift/common/components/NewButton';
import { FormTextField } from '@nshift/common/components/Input/FormTextField';

import { PrivacyNotice } from '@/components/App/AuthPrivacyNotice';
import customResolver from '@/components/CustomResolver';
import { isNil } from 'lodash';

const schema = yup.object().shape({
  username: yup.string().max(STRING_LONG_MAX_LENGTH).label(translations.inputs.userName.label).required()
});

export const ForgotPasswordPage: React.FC = () => {
  const intl = useIntl();
  const [error, setError] = React.useState<string | undefined>();
  const [activationSuccess, setActivationSuccess] = React.useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isValid }
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: customResolver(schema)
  });

  const onSubmit = handleSubmit((data) =>
    resetPassword(data.username)
      .then(() => {
        setActivationSuccess(true);
      })
      .catch((error) => {
        setError(error.response.data.message);
      })
  );

  return (
    <>
      <div className="text-2xl font-bold">
        <FormattedMessage id={translations.pages.forgotPassword.title} />
      </div>

      <div className="mt-2 text-sm text-darkBlue-50">
        <FormattedMessage id={translations.pages.forgotPassword.subtitle} />
      </div>

      <form onSubmit={onSubmit} className="flex flex-col w-full mt-6">
        <FormTextField
          id="username"
          name="username"
          label={intl.formatMessage({ id: translations.inputs.userName.label })}
          control={control}
          className="w-full"
        />

        <div className="flex h-10">
          {activationSuccess && isNil(error) && (
            <div className="my-auto text-confirmingGreen">
              <FormattedMessage id={translations.pages.forgotPassword.emailSent} />
            </div>
          )}
          {error && <div className="mb-4 text-warningRed">{error}</div>}
        </div>

        <Button
          isDisabled={isSubmitting || isSubmitSuccessful || !isValid}
          isLoading={isSubmitting}
          type="submit"
          appearance="filled"
          className="justify-center"
        >
          <FormattedMessage id={translations.inputs.buttons.resetPassword} />
        </Button>

        <Link to={urls.signIn} className="mt-4 text-base text-internationalBlue">
          <FormattedMessage id={translations.pages.forgotPassword.loginRegirect} />
        </Link>

        <PrivacyNotice />
      </form>
    </>
  );
};
