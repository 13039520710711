import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@nshift/common/components/NewButton';
import { OverlayModal, OverlayModalRef } from '@nshift/common/components/Modal/OverlayModal';

import { translations } from '@/locales';

export interface ConfirmDialogProps {
  title?: string;
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  isOpen,
  onConfirm,
  onCancel,
  onClose,
  children
}) => {
  const modalRef = React.useRef<OverlayModalRef>(null);

  const openModal = () => {
    modalRef?.current?.open();
  };

  const cancelPress = () => {
    onCancel?.();
    modalRef?.current?.close();
  };

  const confirmPress = () => {
    onConfirm?.();
    modalRef?.current?.close();
  };

  useEffect(() => {
    if (isOpen) {
      openModal();
    }
  }, [isOpen]);

  return (
    <OverlayModal
      ref={modalRef}
      onClose={() => onClose?.()}
      onRenderHeader={() => {
        return title && <h2 className="text-darkBlue">{title}</h2>;
      }}
      onRenderFooter={() => {
        return (
          <div className="flex justify-end gap-2">
            <Button
              appearance="outlined"
              onClick={() => {
                cancelPress?.();
              }}
              className="px-8"
            >
              <FormattedMessage id={translations.modals.confirmationModal.cancelButton} />
            </Button>
            <Button
              appearance="filled"
              onClick={() => {
                confirmPress?.();
              }}
              className="px-8"
            >
              <FormattedMessage id={translations.modals.confirmationModal.confirmButton} />
            </Button>
          </div>
        );
      }}
    >
      {children}
    </OverlayModal>
  );
};
