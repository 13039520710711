import React from 'react';
import { Item } from 'react-stately';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { DraggableList } from '@nshift/common/components/DraggableList';

import type { PrinterGroup } from './types';

interface DraggableItem {
  name: string;
  key: string;
  priority: number;
}

export interface PrinterProfileGroupPriorityListProps {
  groups?: Array<PrinterGroup>;
  activeGroupId: string | null;
  profileName?: string;
  onReorder?: (newPriority: string | number, updatedPriorityList: Array<{ name: string; priority: number }>) => void;
}

export const PrinterProfileGroupPriorityList: React.FC<PrinterProfileGroupPriorityListProps> = ({
  groups,
  activeGroupId,
  profileName,
  onReorder
}) => {
  // const [priorityOrder, setPriorityOrder] = React.useState<Array<{
  //   name: string;
  //   key: string;
  //   priority: number;
  // }> | null>();

  const priorityOrder = React.useMemo<DraggableItem[] | null>(() => {
    const order =
      groups
        ?.find(({ id }) => String(id) === activeGroupId)
        ?.printerProfileAndPriorities?.map(({ name, priority }) => {
          return { name, key: name, priority };
        })
        .sort((a, b) => a.priority - b.priority) || null;

    return activeGroupId ? order : null;
  }, [groups, activeGroupId, profileName]);

  const disabledPriorityKeys = priorityOrder?.reduce((acc, filteredPriority) => {
    if (filteredPriority.name !== profileName) {
      acc.push(filteredPriority.key);
    }
    return acc;
  }, [] as string[]);

  const handleReorder = (event: any, updatedPriorityList: DraggableItem[]) => {
    // Which element was it placed before/after?
    const {
      target: { dropPosition, key }
    } = event;

    const newTargetPriority = calculatePriority(dropPosition, key) ?? '';
    onReorder?.(newTargetPriority, updatedPriorityList);
  };

  /**
   * The API handles the priority calculation in a very specific way.
   * Priorities can at minimum be 0, and at maximum n.
   * However, the priority does not necessarily have to start at 0, and can instead start at any number.
   * If the lowest priority is 4, and and item is dragged to the top, the new priority will be 3.
   * The priorities can skip numbers meaning that the priorities can be e.g. 0, 1, 3, 11, 20.
   */
  const calculatePriority = (dropPosition: 'before' | 'after' | 'on', targetKey: string) => {
    // First lets find the target priority
    const { priority = 0, key } = priorityOrder?.find((priority) => priority.key === targetKey) || {};

    switch (dropPosition) {
      case 'before':
        return priority === 0 ? -1 : priority - 1;
      case 'on':
        return key;
      case 'after':
        return priority + 1;
      default:
        return priority;
    }
  };

  return (
    <div className="flex flex-col gap-1 p-4 rounded-lg shadow bg-internationalBlue-3 min-h-32">
      <div className="text-darkBlue-60">
        <FormattedMessage id={translations.pages.printerProfiles.group.printerProfiles} />
      </div>

      {priorityOrder && (
        <div className="flex flex-col gap-1 min-h-32 bg-darkBlue-3">
          {activeGroupId ? (
            // @ts-ignore
            <DraggableList items={priorityOrder} disabledKeys={disabledPriorityKeys} onReorder={handleReorder}>
              {(item: any) => <Item key={item.key}>{item.name}</Item>}
            </DraggableList>
          ) : (
            <div className="mb-2 text-sm font-normal text-darkBlue-60">
              <FormattedMessage id={translations.pages.printerProfiles.group.noGroupSelected} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
