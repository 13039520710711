import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { orderBy } from 'lodash';

interface Props {
  columns: ColumnDef<Record<string, unknown>, any>[];
  data: Record<string, unknown>[];
  onSelectionChange?: (ids: string[]) => void;
  onRowClick?: (row: Record<string, unknown>) => void;
  rowCount?: number;
  thClassName?: string;
  trClassName?: string;
  noResultsTitle?: string;
  noResultsMessage?: string;
  loading?: boolean;
  sortable?: boolean;
}

export const BaseTable = forwardRef<any, Props>(
  (
    {
      columns,
      data,
      onSelectionChange,
      onRowClick,
      rowCount,
      thClassName,
      trClassName,
      loading = false,
      noResultsTitle,
      noResultsMessage,
      sortable = false
    },
    ref
  ) => {
    const [rowSelection, setRowSelection] = React.useState({});
    const [sorting, setSorting] = React.useState([]);

    useImperativeHandle(
      ref,
      () => ({
        clearRowSelection: () => setRowSelection({})
      }),
      []
    );

    useEffect(() => {
      onSelectionChange?.(Object.keys(rowSelection));
    }, [rowSelection]);

    const sortedData = useMemo(() => {
      if (sorting.length === 0 || !sortable) return data;
      const [{ id, desc }] = sorting;
      return orderBy(data, [id], [desc ? 'desc' : 'asc']) as Record<string, unknown>[];
    }, [data, sorting]);

    const table = useReactTable({
      data: sortedData,
      columns,
      state: {
        rowSelection,
        sorting
      },
      enableRowSelection: true,
      manualPagination: true,
      manualSorting: true,
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: sortable ? getSortedRowModel() : undefined,
      rowCount,
      debugTable: true
    });

    return (
      <div className="overflow-auto">
        <table className="w-full">
          <TableHead table={table} thClassName={thClassName} flexRender={flexRender} sortable={sortable} />
          <TableBody
            table={table}
            columns={columns}
            loading={loading}
            trClassName={trClassName}
            flexRender={flexRender}
            noResultsTitle={noResultsTitle}
            noResultsMessage={noResultsMessage}
            onRowClick={onRowClick}
          />
        </table>
      </div>
    );
  }
);
