import React from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { useTextField } from 'react-aria';
import type { AriaTextFieldProps } from 'react-aria';

import { Size, sizeToStyleMap } from './constants';

import { RequiredTag } from '@nshift/common/components/RequiredTag';
import { FieldError } from '@nshift/common/components/FieldError';
import { labelStyle, textInputStyle } from '@nshift/common/components/Select/TextInput';

export interface TextFieldProps extends React.PropsWithChildren<AriaTextFieldProps> {
  label?: string;
  size?: Size;
  description?: string;
  isDisabled?: boolean;
  renderIcon?: () => React.ReactNode;
  onClick?: any;
  onChange?: any;
  value?: any;
  className?: string;
  inputClassName?: string;
  required?: boolean;
  error?: any;
  ref?: any;
}

export const TextField: React.FC<TextFieldProps> = React.forwardRef(
  (
    {
      size = Size.Medium,
      isDisabled = false,
      error,
      label,
      onChange,
      onClick,
      value,
      description,
      renderIcon,
      className,
      inputClassName,
      required,
      children,
      ...props
    },
    ref
  ) => {
    const inputRef = React.useRef(null);
    const [isFocused, setIsFocused] = React.useState(false);

    const { labelProps, inputProps, descriptionProps, errorMessageProps, isInvalid } = useTextField(
      { isDisabled, isInvalid: !!error, label, description, ...props },
      inputRef
    );

    const handleFocus = (event) => {
      setIsFocused(true);
      props.onFocus?.(event);
    };

    const handleBlur = (event) => {
      setIsFocused(false);
      props.onBlur?.(event);
    };

    React.useImperativeHandle(ref, () => inputRef.current!, [inputProps]);

    return (
      <div className={classNames('relative w-fit group', className)}>
        <label
          {...labelProps}
          className={labelStyle({
            focused: isFocused || !isNil(value),
            invalid: !isNil(error),
            disabled: isDisabled
          })}
        >
          {label}
          {required && <RequiredTag />}
        </label>

        <input
          {...{ ...inputProps, onChange, value }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onClick={onClick}
          ref={inputRef}
          name={props.name}
          className={textInputStyle({ className: classNames(inputClassName) })}
        />

        {children}

        {renderIcon && <div className="absolute top-0 flex items-center h-full right-3">{renderIcon()}</div>}

        {description && (
          <legend
            {...descriptionProps}
            className={classNames(
              'mt-2',
              error ? 'text-warningRed' : 'text-darkBlue-60',
              isDisabled && '!text-darkBlue-20',
              sizeToStyleMap[size].descriptionStyle
            )}
          >
            {description}
          </legend>
        )}

        {isInvalid && (
          <legend {...errorMessageProps}>
            <FieldError error={error} pathOverride={label} />
          </legend>
        )}
      </div>
    );
  }
);
