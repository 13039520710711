import React, { FC } from 'react';

import { TableEmpty } from './TableEmpty';
import { LoadingSize, Spinner, SpinnerColor } from '../../Loading';
import { TableRows } from './TableRows';
import { ColumnDef, flexRender, Table } from '@tanstack/react-table';

interface Props {
  table: Table<Record<string, unknown>>;
  trClassName?: string;
  flexRender: typeof flexRender;
  columns: ColumnDef<Record<string, unknown>, any>[];
  noResultsTitle: string;
  noResultsMessage: string;
  loading?: boolean;
  onRowClick: (row: Record<string, unknown>) => void;
}

export const TableBody: FC<Props> = ({
  table,
  flexRender,
  columns,
  noResultsTitle,
  noResultsMessage,
  trClassName,
  loading = false,
  onRowClick
}) => {
  return (
    <tbody>
      {!loading &&
        (table.getRowModel()?.rows?.length > 0 ? (
          <TableRows table={table} flexRender={flexRender} trClassName={trClassName} onRowClick={onRowClick} />
        ) : (
          <tr className="text-center">
            <td colSpan={columns.length}>
              <TableEmpty title={noResultsTitle} message={noResultsMessage} />
            </td>
          </tr>
        ))}
      {loading && (
        <tr>
          <td colSpan={columns.length}>
            <div className="flex justify-center items-center py-12">
              <Spinner size={LoadingSize.Large} fillColor={SpinnerColor.Primary} isIndeterminate />
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};
