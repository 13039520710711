import React, { FC } from 'react';
import { PageNotFound } from '@nshift/common/components/ErrorPages';
import { useIntl } from 'react-intl';
import { translations } from '@/locales';
import { urls } from '@/constants';

const text = translations.application.pageNotFound;

export const PageNotFoundPrint: FC = () => {
  const intl = useIntl();

  return (
    <PageNotFound
      buttonText={intl.formatMessage({ id: text.buttonText })}
      buttonNavigationLink={urls.home}
      subtitle={intl.formatMessage({ id: text.subtitle })}
      title={intl.formatMessage({ id: text.title })}
    />
  );
};
