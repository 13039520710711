import { Icon } from '../Icon';
import { TableRowActionsMenu } from './BaseTable/TableRowActionsMenu';
import { TableCheckbox } from './TableCheckbox';
import React from 'react';

export const tablePageSizeOptions = [
  { value: '5', id: '5' },
  { value: '10', id: '10' },
  { value: '15', id: '15' },
  { value: '30', id: '30' },
  { value: '50', id: '50' }
];

export const rowSingleActionColumn = (id, onActionButtonClick) => ({
  accessorKey: id,
  header: '', // () => <Icon type="meatball" appearance="white" size="small" className="ml-1" />,
  cell: ({ row }) => (
    <Icon
      type="chevronCircleRight"
      appearance="blue"
      className="cursor-pointer"
      onClick={() => {
        onActionButtonClick(row);
      }}
    />
  )
});

export const rowActionsColumn = (rowActionButtons, onRowClick) => ({
  accessorKey: 'rowActionsMenu',
  header: '',
  cell: ({ row }) => (
    <TableRowActionsMenu
      row={row.original}
      actionButtons={rowActionButtons}
      openDetailsDrawerFn={() => {
        onRowClick?.(row.original);
      }}
    />
  )
});

export const checkboxColumn = {
  id: 'check',
  header: ({ table }) => (
    <TableCheckbox
      {...{
        checked: table?.getIsAllRowsSelected?.(),
        indeterminate: table?.getIsSomeRowsSelected?.(),
        onChange: table?.getToggleAllRowsSelectedHandler?.()
      }}
    />
  ),
  cell: ({ row }) => (
    <div
      className="flex items-center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TableCheckbox
        {...{
          checked: row?.getIsSelected?.(),
          disabled: !row?.getCanSelect?.(),
          indeterminate: row?.getIsSomeSelected?.(),
          onChange: row?.getToggleSelectedHandler?.()
        }}
      />
    </div>
  )
};
