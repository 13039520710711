import stringify from 'query-string';
import { AxiosRequestHeaders } from 'axios';

import { getBaseURL } from '../getBaseURL';

import AuthorizedApiClient, { AuthorizedHttpRequestConfig } from '@nshift/common/services/network/authorized-api';

export const authorizedApi = new AuthorizedApiClient<AuthorizedHttpRequestConfig>({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json'
    // [Header.Version]: RELEASE
    //This is a known issue in this version of axios, it's just a typescript error, but the code works
  } as unknown as AxiosRequestHeaders,
  paramsSerializer: (params) => stringify.stringify(params, { skipNull: true }),
  errorReporting: {
    ignoreStatus: [401],
    reportError: (error) => console.log('mock error reporting request', error)
  },
  refreshRetries: 3
});
