import React from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import type { ComboBoxProps, Key, ListBoxItemProps } from 'react-aria-components';
import { Button, ComboBox, Group, Input, Label, ListBox, ListBoxItem, Popover } from 'react-aria-components';

import { Icon } from '@nshift/common/components/Icon/IconNew';
import { FieldError } from '@nshift/common/components/FieldError';
import { RequiredLabel } from '@nshift/common/components/RequiredTag';

import { labelStyle, textInputStyle } from './TextInput';

export interface BaseComboBoxProps extends ComboBoxProps<any> {
  label?: string;
  items?: { id: string; value: string }[];
  value: string;
  onChange: (event: unknown) => void;
  children?: React.ReactNode;
  className?: string;
  error?: any;
}

export const BaseCombobox: React.FC<BaseComboBoxProps> = ({
  label,
  items,
  value,
  onChange,
  error,
  children,
  isRequired,
  ...props
}) => {
  const [fieldState, setFieldState] = React.useState({
    selectedKey: (value?.[0] as Key) ?? undefined,
    inputValue: items.find((item) => item.id === value?.[0])?.value ?? ''
  });

  const onSelectionChange = (id?: Key) => {
    onChange(id ? [id] : undefined);

    setFieldState({
      inputValue: items?.find((item) => item.id === id)?.value ?? '',
      selectedKey: id ? id : undefined
    });
  };

  const onInputChange = (value: string) => {
    setFieldState((prevState) => ({
      inputValue: value,
      selectedKey: value === '' ? undefined : prevState.selectedKey
    }));
  };

  return (
    <ComboBox
      selectedKey={fieldState.selectedKey}
      inputValue={fieldState.inputValue}
      onInputChange={onInputChange}
      onSelectionChange={onSelectionChange}
      menuTrigger="focus"
      className="flex flex-col w-full gap-1"
      {...props}
    >
      <Group className="relative w-full group">
        {({ isDisabled, isFocusWithin }) => (
          <>
            <Label
              className={labelStyle({
                focused: isFocusWithin || !isNil(value),
                invalid: !isNil(error),
                disabled: isDisabled
              })}
            >
              <RequiredLabel {...{ label, isRequired, isDisabled }} />
            </Label>

            <div className="relative flex w-full">
              <Input className={textInputStyle({ withTrailingIcon: true, className: classNames('pr-20') })} />

              <div className="absolute inset-y-0 flex items-center justify-end ml-auto right-3">
                {fieldState.inputValue && (
                  <Button onPress={() => onSelectionChange()} className="flex justify-center cursor-pointer ">
                    <Icon type="close" size="small" aria-hidden="true" light={isDisabled} />
                  </Button>
                )}

                <Button className="flex items-center justify-center pl-2 ml-3 border-l border-l-darkBlue-30">
                  <Icon type="chevronDownAlt" size="small" aria-hidden="true" light={isDisabled} />
                </Button>
              </div>
            </div>

            <FieldError error={error} pathOverride={label} />
          </>
        )}
      </Group>

      <Popover className="w-[--trigger-width] flex z-10 shadow border-darkBlue-5 bg-white rounded-md entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out">
        <ListBox className="w-full p-2 space-y-1 overflow-y-auto outline-none max-h-60">
          {children}

          {items.map((item) => (
            <SelectItem textValue={item.value} key={item.id} id={item.id} aria-label={item.value}>
              {item.value}
            </SelectItem>
          ))}
        </ListBox>
      </Popover>
    </ComboBox>
  );
};

export const SelectItem: React.FC<React.PropsWithChildren<ListBoxItemProps>> = ({ children, ...props }) => (
  <ListBoxItem
    {...props}
    className={({ isSelected, isPressed, isFocusVisible, isFocused, isHovered, isDisabled }) =>
      classNames(
        'flex items-center flex-1 gap-2 truncate cursor-pointer rounded text-sm h-8 px-2',
        isDisabled ? 'text-darkBlue-40' : 'text-darkBlue-70',
        isHovered && 'bg-highlightingBlue-5',
        isPressed && 'pressed',
        isFocusVisible && 'focus-visible',
        isSelected && 'bg-highlightingBlue-30',
        isFocused && 'bg-highlightingBlue-5 focus:outline-highlightingBlue'
      )
    }
  >
    {({ isSelected }) => (
      <>
        {children}

        {isSelected && <Icon type="checkmark" size="xsmall" className="ml-auto" />}
      </>
    )}
  </ListBoxItem>
);
