import { translations } from '@/locales';
import * as yup from 'yup';

export const printerProfileSchema = yup.object().shape({
  printerId: yup.array().of(yup.string().required()).min(1, translations.validation.basic.mixed.required).required(),
  clientId: yup.array().of(yup.string().required()).min(1, translations.validation.basic.mixed.required).required(),
  active: yup.boolean(),
  profileName: yup.string().required().label(translations.pages.printerProfiles.columnsFilters.profileName),
  format: yup.string().required().label(translations.pages.printerProfiles.columnsFilters.format),
  media: yup.array().of(yup.string()).required().label(translations.pages.printerProfiles.columnsFilters.media),
  offsetX: yup.number().nullable().label(translations.pages.printerProfiles.columnsFilters.offsetX),
  offsetY: yup.number().nullable().label(translations.pages.printerProfiles.columnsFilters.offsetY),
  layouts: yup.string().label(translations.pages.printerProfiles.columnsFilters.layouts)
});
