import * as React from 'react';
import classnames from 'classnames';

import { Button } from '../NewButton';
import { Icon } from '../Icon';

export const TableRowDetailsDrawer = ({ row, actionButtons, className = '', onClose, children }) => {
  return (
    <div
      className={classnames(
        'z-20 fixed bottom-0 right-0 w-96 bg-highlightingBlue-3 top-[104px] overflow-y-scroll shadow',
        className
      )}
    >
      <div className="relative flex h-full">
        <Button appearance="ghost" className="w-8 !p-0 h-full rounded-none" onClick={onClose}>
          <div className="flex items-center justify-center w-6 h-6 mx-auto rounded-full bg-highlightingBlue">
            <Icon type="chevronRight" appearance="white" sizeClassName="text-xs" aria-hidden="true" />
          </div>
        </Button>

        <div className="flex flex-col justify-between w-full h-full">
          <div className="mt-8">{children}</div>

          <div className="flex flex-col w-full py-4 space-y-4">
            {/* TODO add types here */}
            {actionButtons?.map(({ id, label, handlerFn, shouldCloseDrawer }) => (
              <Button
                key={id}
                onClick={() => {
                  handlerFn(row);

                  shouldCloseDrawer && onClose();
                }}
                appearance="outlined"
                className="justify-center"
              >
                {label}
              </Button>
            ))}
          </div>
        </div>

        <div className="w-8" />
      </div>
    </div>
  );
};
