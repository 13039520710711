import * as React from 'react';
import { Icon } from '../../Icon';
import { Button } from '../../Button';
import { RowActionButton } from '../types';
import { Popover } from '../../Modal';
import { useOverlayTrigger, usePreventScroll } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

interface Props {
  row: Record<string, unknown>;
  openDetailsDrawerFn: (row: Record<string, unknown>) => void;
  actionButtons: RowActionButton[];
}

export const TableRowActionsMenu: React.FC<Props> = ({ actionButtons, row, openDetailsDrawerFn, ...props }) => {
  const menuButtonRef = React.useRef(null);
  const popoverRef = React.useRef(null);
  const preventDefault = usePreventScroll();

  const state = useOverlayTriggerState(props);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    state.open();
    event.stopPropagation();
    preventDefault;
  };

  const handleOpenDetailsDrawer = () => {
    state.close();
    openDetailsDrawerFn(row);
  };

  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'menu' }, state, popoverRef);

  return (
    <div>
      <Button
        className="!px-0 aspect-square flex items-center justify-center"
        onClick={handleClick}
        ref={menuButtonRef}
      >
        <Icon type="meatball" appearance="none" sizeClassName="text-4 leading-4 md:text-4 md:leading-[18px]" />
      </Button>
      {state.isOpen && (
        <Popover {...overlayProps} triggerRef={menuButtonRef} popoverRef={popoverRef} state={state}>
          <div className="flex flex-col p-1 bg-white rounded-lg shadow">
            {actionButtons.map(({ id, label, handlerFn }) => (
              <Button
                key={id}
                {...triggerProps}
                className="rounded-md !justify-start pr-9 h-9 text-sm"
                onClick={(event) => {
                  event.stopPropagation();
                  handlerFn(row.id as typeof row, handleOpenDetailsDrawer);
                  state.close();
                }}
              >
                {label}
              </Button>
            ))}
          </div>
        </Popover>
      )}
    </div>
  );
};
