import React, { FC } from 'react';
import classnames from 'classnames';
import { flexRender, Table } from '@tanstack/react-table';

interface Props {
  table: Table<Record<string, unknown>>;
  trClassName?: string;
  flexRender: typeof flexRender;
  onRowClick?: (row: Record<string, unknown>) => void;
}

export const TableRows: FC<Props> = ({ table, trClassName, flexRender, onRowClick }) => {
  return (
    <>
      {table.getRowModel().rows.map((row) => (
        <tr
          key={row.id}
          className={classnames(
            'text-xs text-darkBlue-70 border-b border-internationalBlue-5',
            'hover:bg-internationalBlue-5 hover:text-highlightingBlue hover:border-collapse transition-colors odd:bg-darkBlue-3',
            trClassName
          )}
          onClick={() => onRowClick?.(row.original)}
        >
          {row.getVisibleCells().map((cell) => (
            <td className="first:pl-4 px-1 py-2" key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};
