import { generatePath } from 'react-router';

export const signIn = `/login`;
export const signUp = `/register`;
export const activate = `/activate`;

export const forgotPassword = `/forgot-password`;

export const resetPassword = {
  main: `/reset`,
  expired: `/reset/expired`
};

export const home = `/`;

export const overview = '/overview';

export const dashboard = '/dashboard';

export const notFound = '/not-found';

export const setUp = {
  base: '/setup',
  clients: '/setup/clients',
  printers: '/setup/printers'
};

export const printers = {
  base: '/printers',
  profiles: {
    base: '/printers/profiles',
    detail: '/printers/profiles/:id',
    create: '/printers/profiles/create'
  },

  getDetailUrl: (id: string) => generatePath(`/printers/profiles/:id`, { id })
};

export const printJobs = {
  base: '/printJobs'
};

export const client = {
  base: '/clients',
  detail: '/clients/:id',
  getDetailUrl: (id: string) => generatePath(`/clients/:id`, { id })
};
