import { translations } from '@/locales';
import { GenericError } from '@nshift/common/components/ErrorPages/GenericError';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

const errorBoundary = translations.application.errorBoundary;

interface Props {
  buttonNavigationLink?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  hasTryAgainButton?: boolean;
}

export const PrintErrorFallback: FC<Props> = (props) => {
  const intl = useIntl();
  return (
    <GenericError
      {...props}
      title={intl.formatMessage({ id: errorBoundary.title })}
      message={intl.formatMessage({ id: errorBoundary.message })}
      tryAgainButtonText={intl.formatMessage({ id: errorBoundary.tryAgainButtonText })}
    />
  );
};
