import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { deletePrinterProfile, getPrinterProfiles } from '@/services/api';

import { toast } from '@nshift/common/components/Toast';
import { Button } from '@nshift/common/components/NewButton';
import { SortableTable } from '@nshift/common/components/Table';

import { PrintErrorFallback } from '@/components/PrintErrorFallback';
import { ConfirmDialog } from '@/components/Dialogs';

import { usePrinterProfileConfig } from './PrinterProfilesTableConfig';
import { PrinterProfileDetailsView } from './PrinterProfileDetailsView';

import { PrinterProfileDialogs } from './types';

const PrinterProfilesPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    data: printerProfiles = [],
    isLoading,
    isError,
    refetch
  } = useQuery<any>('get-printer-profiles', () =>
    getPrinterProfiles().then((result) => result.sort((a: any, b: any) => (a.id < b.id ? -1 : 1)))
  );

  const [openedDialog, setOpenedDialog] = useState<any>();
  const { colsConfig, rowActionButtons } = usePrinterProfileConfig({
    onDialogOpen: setOpenedDialog
  });
  const queryClient = useQueryClient();

  return (
    <div className="w-full py-20">
      <div className="flex items-center justify-between px-6 mb-6">
        <h1 className="text-3xl font-semibold">
          <FormattedMessage id={translations.pages.printerProfiles.title} />
        </h1>

        {!isError && (
          <Button appearance="filled" onClick={() => navigate(urls.printers.profiles.create)}>
            <FormattedMessage id={translations.pages.printerProfiles.buttons.addProfile} />
          </Button>
        )}
      </div>

      {!isError ? (
        <div className="flex flex-col mx-6 overflow-hidden bg-white rounded-lg shadow">
          <SortableTable
            id="PrinterProfilesTable"
            data={printerProfiles}
            columns={colsConfig}
            rowActionButtons={rowActionButtons}
            drawerActionButtons={rowActionButtons}
            noResultsTitle={intl.formatMessage(
              { id: translations.miscellaneous.table.empty.title },
              { items: intl.formatMessage({ id: translations.pages.printerProfiles.items }) }
            )}
            noResultsMessage={intl.formatMessage({ id: translations.miscellaneous.table.empty.message })}
            loading={isLoading}
            renderRowDetailsSection={(row: any) => <PrinterProfileDetailsView row={row} />}
          />
          <ConfirmDialog
            isOpen={openedDialog?.name === PrinterProfileDialogs.Delete}
            title={intl.formatMessage({ id: translations.pages.printerProfiles.dialogs.delete.title })}
            onClose={() => {
              setOpenedDialog(undefined);
            }}
            onConfirm={() => {
              deletePrinterProfile(openedDialog?.rowIds?.[0])
                .then(() => {
                  toast.success(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.deleteSuccess }));
                  queryClient.invalidateQueries('get-printer-profiles');
                })
                .catch(() => {
                  toast.error(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.deleteError }));
                });
            }}
          >
            <FormattedMessage
              id={translations.pages.printerProfiles.dialogs.delete.message}
              values={{ count: (openedDialog?.rowIds ?? []).length }}
            />
          </ConfirmDialog>
        </div>
      ) : (
        <PrintErrorFallback
          buttonText={intl.formatMessage({ id: translations.application.errorBoundary.tryAgainButtonText })}
          onButtonClick={() => refetch()}
        />
      )}
    </div>
  );
};

export default PrinterProfilesPage;
