import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import illustration from '@/assets/deliveryCar.svg';
import { SimpleFooter } from '@/components/App/SimpleFooter';
import { Outlet } from 'react-router-dom';

export const AuthBasePage: React.FC<PropsWithChildren> = () => (
  <>
    <div className="relative flex items-center justify-center w-full h-[calc(100vh-50px)] max-xs:px-6 bg-gradient-to-b from-nordicGreen to-internationalBlue">
      <div className="">
        <div className="mb-4 text-5xl text-white">
          <FormattedMessage id={translations.application.welcome} />
        </div>

        <img src={illustration} className="w-full max-w-xl mr-6 max-lg:hidden" />
      </div>

      <div className="z-10 flex flex-col w-full max-w-md p-6 overflow-hidden bg-white rounded-lg shadow">
        <Outlet />
      </div>
    </div>
    <SimpleFooter />
  </>
);
