import React, { Dispatch, SetStateAction, forwardRef } from 'react';
import { useGridList } from 'react-aria';
import { useListState } from 'react-stately';

import { PrinterProfileGridListItem } from './PrinterProfileGridListItem';

export interface GridListProps {
  ref: React.RefObject<HTMLUListElement>;
  items: any[];
  selectedKeys?: any[] | number[];
  onSelectionChange?: Dispatch<SetStateAction<Set<any>>>;
  selectionMode: 'single' | 'multiple';
  type?: 'checkboxList' | 'list';
  children: any;
  style?: React.CSSProperties;
}

export const GridList = forwardRef<HTMLUListElement, GridListProps>(
  ({ type = 'checkboxList', style, ...props }, ref) => {
    const state = useListState(props as any);
    const { gridProps } = useGridList(props as any, state, ref as any);
    const itemType = type === 'checkboxList' ? 'checkboxItem' : 'item';

    return (
      <ul
        {...gridProps}
        ref={ref}
        {...{ style }}
        className="w-full p-2 space-y-1 overflow-y-auto border-none rounded-md max-h-64"
      >
        {[...(state.collection || [])].map((item) => (
          <PrinterProfileGridListItem type={itemType} key={item.key} item={item} state={state} />
        ))}
      </ul>
    );
  }
);
