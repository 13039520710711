import React from 'react';

import { AriaTooltipProps, mergeProps, useTooltip, useTooltipTrigger } from 'react-aria';
import { useTooltipTriggerState, TooltipTriggerState } from 'react-stately';

import { Popover } from '../Modal';

export const Tooltip = ({ children, ...props }) => {
  const state = useTooltipTriggerState(props);

  const ref = React.useRef(null);
  const popoverRef = React.useRef(null);

  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);
  return (
    <span {...triggerProps} ref={ref}>
      {children}
      {state.isOpen && (
        // @ts-ignore - setOpen and toggle is not needed for tooltips
        <Popover triggerRef={ref} state={state} popoverRef={popoverRef} showArrow>
          <TooltipContent state={state} {...tooltipProps}>
            {props.tooltip}
          </TooltipContent>
        </Popover>
      )}
    </span>
  );
};

interface TooltipProps extends AriaTooltipProps {
  state: TooltipTriggerState;
  children: React.ReactNode;
}

const TooltipContent: React.FC<TooltipProps> = ({ state, children, ...props }) => {
  const { tooltipProps } = useTooltip(props, state);

  return (
    <div className="p-2 text-xs text-white rounded bg-darkBlue" {...mergeProps(props, tooltipProps)}>
      {children}
    </div>
  );
};
