import { authorizedApi } from '@/services/network';

import { isEmpty } from 'lodash';

// TODO move these types into the domains folder in another PR
import { ClientBlockedAction, ClientFilters } from '@/pages/Client/types';

export const getClientList = (page = 0, size?: number, filters?: ClientFilters) => {
  const { name, id, ip, statuses, blocked } = filters || {};

  return authorizedApi.post<{ clients: Array<{ clientId: number; name: string }> }>('/clients', {
    page,
    size,
    names: isEmpty(name) ? [] : [name],
    clientIds: isEmpty(id) ? [] : [id],
    ips: isEmpty(ip) ? [] : [ip],
    statuses,
    blocked
  });
};

export const toggleClientBlockedStatus = (blockedState: ClientBlockedAction, clientIds: Array<string | number>) =>
  authorizedApi.post<any>(`/clients/actions/${blockedState}`, { ids: clientIds });
