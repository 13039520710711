import React, { FC } from 'react';
import classnames from 'classnames';
import { AriaSwitchProps } from 'react-aria';

interface Props extends AriaSwitchProps {
  id?: string;
  label?: string;
  name: string;
  register: any;
  labelClassName?: string;
  isDisabled?: boolean;
}

export const FormToggle: FC<Props> = ({ register, name, id, label, labelClassName, isDisabled }) => {
  return (
    <div className="flex items-center">
      <label className="relative inline-flex items-center cursor-pointer">
        <input disabled={isDisabled} {...{ ...register(name), id }} type="checkbox" className="sr-only peer" />
        <div
          className={classnames(
            'peer h-6 w-12 rounded-full relative',
            isDisabled
              ? 'bg-darkBlue-5 peer-checked:bg-darkBlue-40'
              : 'bg-internationalBlue-20 peer-checked:bg-highlightingBlue',
            'after:absolute after:left-[2px] after:top-[2px] after:h-[20px] after:w-[20px] after:rounded-full after:bg-white after:content-[""]',
            'peer-checked:after:translate-x-full peer-checked:after:left-[6px] duration-500 after:duration-200 after:transition-all transition-all'
          )}
        />
        <label className={classnames('ml-2 text-darkBlue-80', labelClassName)} htmlFor={id}>
          {label}
        </label>
      </label>
    </div>
  );
};
