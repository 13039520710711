import React from 'react';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locales';

import { Button } from '@nshift/common/components/NewButton';
import { FormTextField } from '@nshift/common/components/Input/FormTextField';

import { Session } from '@/containers/SessionContainer';
import { PrivacyNotice } from '@/components/App/AuthPrivacyNotice';
import customResolver from '@/components/CustomResolver';

const schema = yup.object().shape({
  username: yup.string().required().label(translations.inputs.email.label),
  password: yup.string().required().label(translations.inputs.password.label)
});

export const SignInPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { loginWithEmailAndPassword } = Session.useContainer();
  const [error, setError] = React.useState<string | undefined>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: customResolver(schema)
  });

  const onSubmit = handleSubmit((data) =>
    loginWithEmailAndPassword(data)
      .then(() => navigate(urls.home))
      .catch((error) => setError(error.response.data.message))
  );

  return (
    <>
      <div className="text-2xl font-bold">
        <FormattedMessage id={translations.pages.signIn.title} />
      </div>

      <div className="mt-2 text-sm text-darkBlue-50">
        <FormattedMessage
          id={translations.pages.signIn.signUpRedirect}
          values={{
            span: ((text: string) => (
              <Link to={urls.signUp} className="underline">
                {text}
              </Link>
            )) as unknown as React.ReactNode
          }}
        />
      </div>

      <form onSubmit={onSubmit} className="flex flex-col mt-6">
        <FormTextField
          control={control}
          id="username"
          name="username"
          label={intl.formatMessage({ id: translations.inputs.userName.label })}
          className="w-full"
        />

        <FormTextField
          type="password"
          id="password"
          name="password"
          label={intl.formatMessage({ id: translations.inputs.password.label })}
          control={control}
          className="w-full mt-4"
        />

        <div className="flex h-10 pt-2 text-sm text-warningRed">{error}</div>

        <Button
          type="submit"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          appearance="filled"
          className="justify-center"
        >
          <FormattedMessage id={translations.inputs.buttons.signIn} />
        </Button>

        <Link to={urls.forgotPassword} className="mt-4 text-internationalBlue">
          <FormattedMessage id={translations.pages.signIn.forgotPasswordRedirect} />
        </Link>

        <PrivacyNotice />
      </form>
    </>
  );
};
