import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locales';
import { passwordSchema } from '@/schemas';
import { activatePassword, checkToken } from '@/services/api';

import { Button } from '@nshift/common/components/NewButton';
import { FormTextField } from '@nshift/common/components/Input/FormTextField';

import { PrivacyNotice } from '@/components/App/AuthPrivacyNotice';
import customResolver from '@/components/CustomResolver';
import { isNil } from 'lodash';

const schema = yup.object().shape({
  password: passwordSchema,
  passwordConfirmation: passwordSchema.oneOf([yup.ref('password')], translations.validation.custom.passwordsDontMatch)
});

export const ActivatePage: React.FC = () => {
  const intl = useIntl();

  const { search } = useLocation();

  const token = new URLSearchParams(search).get('token') ?? '';

  const [error, setError] = React.useState<string | undefined>();

  const [activationSuccess, setActivationSuccess] = React.useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting, isValid }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: customResolver(schema)
  });

  const onSubmit = handleSubmit((data) => {
    checkToken(token)
      .then(() => activatePassword(data.password, token).then(() => setActivationSuccess(true)))
      .catch((error) => {
        setError(error.response.data.message);
      });
  });

  return (
    <>
      <div className="text-2xl font-bold">
        <FormattedMessage id={translations.pages.activate.title} />
      </div>

      <div className="mt-4 text-sm text-darkBlue-50">
        <FormattedMessage id={translations.pages.activate.subtitle} />
      </div>

      <form onSubmit={onSubmit} className="flex flex-col w-full mt-10">
        <FormTextField
          type="password"
          id="password"
          name="password"
          label={intl.formatMessage({ id: translations.inputs.newPassword.label })}
          control={control}
          className="w-full"
        />

        <FormTextField
          type="password"
          id="passwordConfirmation"
          name="passwordConfirmation"
          label={intl.formatMessage({ id: translations.inputs.newPasswordConfirmation.label })}
          control={control}
          className="w-full mt-4"
        />

        <div className="flex items-center min-h-10">
          {activationSuccess && isNil(error) && (
            <div className="text-base text-confirmingGreen">
              <FormattedMessage
                id={translations.pages.activate.accountActivated}
                values={{
                  a: ((text: string) => (
                    <Link to={urls.signIn} className="underline text-internationalBlue">
                      {text}
                    </Link>
                  )) as unknown as React.ReactNode
                }}
              />
            </div>
          )}
          {error && <div className="my-auto text-warningRed">{error}</div>}
        </div>

        <Button
          isDisabled={isSubmitting || isSubmitSuccessful || !isValid}
          isLoading={isSubmitting}
          type="submit"
          appearance="filled"
          className="justify-center"
        >
          <FormattedMessage id={translations.inputs.buttons.confirmPassword} />
        </Button>

        <PrivacyNotice />
      </form>
    </>
  );
};
