import React from 'react';
import { createContainer } from 'unstated-next';

import { authorizedApi } from '@/services/network';
import { getProfile, login, LoginOptions, logout as apiLogout, refreshAuth } from '@/services/api';
import { storage } from '@/services';
import { Me } from 'app/src/domains/Me';
import { AxiosError } from 'axios';
import { Buffer } from 'buffer/';

enum ActionType {
  UpdateSession = 'update-session',
  ClearSession = 'clear-session',
  Error = 'error'
}

interface LoggedInAction {
  type: ActionType.UpdateSession;
  me?: {
    name: string;
    companyName: string;
  };
  session: Session;
}

interface LoggedOutAction {
  type: ActionType.ClearSession;
}

interface ErrorAction {
  type: ActionType.Error;
  error: Error;
}

export type Action = LoggedInAction | LoggedOutAction | ErrorAction;

interface Session {
  accessToken: string;
  refreshToken?: string;
}

export interface State {
  loading: boolean;
  session?: Session;
  error?: Error;
  me?: Me;
}

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.UpdateSession: {
      const { me, session } = action;

      return { loading: false, me, session };
    }
    case ActionType.ClearSession:
      return { loading: false };
    case ActionType.Error:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const useSession = () => {
  const [state, dispatch] = React.useReducer(reducer, { loading: true, session: storage.getItem<Session>('session') });

  const onSessionUpdated = (session?: Session) => () => {
    storage.setItem('session', session);

    authorizedApi.token = session?.accessToken ?? null;
  };

  const clearSession = () => dispatch({ type: ActionType.ClearSession });

  React.useEffect(() => {
    onSessionUpdated(state.session)();

    authorizedApi.configure({
      onUnauthorized: () => Promise.resolve().then(clearSession).then(onSessionUpdated()),
      onAccessTokenExpired: refreshAuthToken
    });
  }, [state.session]);

  const loadMe = React.useCallback(async () => {
    const session = state.session;

    if (!session) return;

    const decoded = Buffer.from(state.session?.accessToken || '', 'base64').toString('utf8');
    const companyNameKeyIndex = decoded.split('"').findIndex((el) => el === 'companyName');
    const companyNameValue = decoded.split('"').splice(companyNameKeyIndex, 3)[2];

    try {
      const me = await getProfile();
      dispatch({ type: ActionType.UpdateSession, me: { ...me, companyName: companyNameValue }, session });
      return { ...me, companyName: companyNameValue };
    } catch {
      clearSession();
    }
  }, [state.session]);

  React.useEffect(() => {
    loadMe();
  }, [loadMe]);

  const loginWithEmailAndPassword = React.useCallback(async (options: LoginOptions) => {
    try {
      const { accessToken, refreshToken } = await login(options);
      dispatch({ type: ActionType.UpdateSession, session: { accessToken, refreshToken } });
      onSessionUpdated({ accessToken, refreshToken })();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch({ type: ActionType.Error, error });
        throw error;
      } else {
        console.error(error);
      }
    }
  }, []);

  const refreshAuthToken = async () => {
    try {
      const { accessToken, refreshToken } = await refreshAuth(state.session?.refreshToken ?? '');
      dispatch({ type: ActionType.UpdateSession, session: { accessToken, refreshToken } });
      onSessionUpdated({ accessToken, refreshToken })();
    } catch (error) {
      console.error(error);
      clearSession();
      onSessionUpdated()();
    }
  };

  React.useEffect(() => {
    // analytics go here (link previous actions with user email address/username/id)
  }, [state.me]);

  const logout = React.useCallback(async () => {
    try {
      await apiLogout();
    } catch (err) {
      console.error(err);
    } finally {
      clearSession();
      onSessionUpdated()();
    }
  }, []);

  return React.useMemo(
    () => ({
      loading: state.loading,
      error: state.error,
      me: state.me,
      authenticated: !!state.session,
      loadMe,
      logout,
      loginWithEmailAndPassword
    }),
    [state.loading, state.error, state.me, state.session, logout, loadMe, loginWithEmailAndPassword]
  );
};

export const Session = createContainer(useSession);
