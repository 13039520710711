import React from 'react';
import { useIntl } from 'react-intl';

export const getErrorMessage = (error: any, pathOverride?: string) => {
  const intl = useIntl();

  return (
    error &&
    intl.formatMessage(
      { id: error.message },
      {
        ...error.params,
        path: pathOverride || (error.params.label ? intl.formatMessage({ id: error.params.label }) : error.path)
      }
    )
  );
};

interface Props {
  error?: any;
  pathOverride?: string;
}

export const FieldError: React.FC<Props> = ({ error, pathOverride }) =>
  error ? (
    <div className="text-sm text-left text-warningRed entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out">
      {getErrorMessage(error, pathOverride.toLowerCase())}
    </div>
  ) : (
    <></>
  );
