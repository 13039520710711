import * as yup from 'yup';

import { translations } from '@/locales';
import { MAX_FILE_SIZE, STRING_LONG_MAX_LENGTH, SUPPORTED_IMAGE_FORMATS } from '@/constants';

export const emailSchema = yup
  .string()
  .email()
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.email.label)
  .required();

export const passwordSchema = yup
  .string()
  .required()
  .min(8)
  .matches(/[a-z]/, translations.validation.custom.lowercase)
  .matches(/[A-Z]/, translations.validation.custom.uppercase)
  .matches(/[0-9]/, translations.validation.custom.digit)
  .matches(/[!#$%&'()*+,./:;<=>?@^_`|{}~\\\-[\]"]/, translations.validation.custom.specialCharacter)
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.password.label);

export const firstNameSchema = yup
  .string()
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.firstName.label)
  .required();

export const lastNameSchema = yup
  .string()
  .max(STRING_LONG_MAX_LENGTH)
  .label(translations.inputs.lastName.label)
  .required();

export const photoSchema = yup
  .mixed()
  .test({
    name: 'fileSize',
    message: translations.validation.custom.fileSize,
    //@ts-ignore
    test: (value) => !value || !value.size || value.size <= MAX_FILE_SIZE
  })
  .test({
    name: 'fileFormat',
    message: translations.validation.custom.fileFormat,
    //@ts-ignore
    test: (value) => !value || !value.type || SUPPORTED_IMAGE_FORMATS.includes(value.type)
  });
