import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export const useFormUnsavedChangesBlocker = (condition: boolean) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => condition && currentLocation.pathname !== nextLocation.pathname
  );

  const handleBlockOnRefreshOrTabClose = (e) => {
    if (condition) {
      e.preventDefault();
      e.returnValue = '';
      return true;
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBlockOnRefreshOrTabClose, { capture: true });
    return () => {
      window.removeEventListener('beforeunload', handleBlockOnRefreshOrTabClose, { capture: true });
    };
  }, [handleBlockOnRefreshOrTabClose]);

  return {
    isBlocked: blocker.state === 'blocked',
    reset: () => {
      blocker?.reset?.();
    },
    proceed: () => {
      blocker?.proceed?.();
    }
  };
};
