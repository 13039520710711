import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import classnames from 'classnames';
import { DismissButton, Overlay, useModalOverlay } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { Icon } from '../Icon';

export interface OverlayModalProps {
  isDismissable?: boolean;
  isKeyboardDismissDisabled?: boolean;
  showHeaderBorder?: boolean;
  ref?: any;
  children: React.ReactNode;
  onRenderHeader?: () => React.ReactNode;
  onRenderFooter?: () => React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
}

export interface OverlayModalRef {
  open: () => void;
  close: () => void;
}

export const OverlayModal: React.FC<OverlayModalProps> = forwardRef(
  (
    {
      children,
      isDismissable = true,
      isKeyboardDismissDisabled = false,
      onOpen,
      onClose,
      onRenderHeader,
      onRenderFooter,
      showHeaderBorder = true
    },
    ref
  ) => {
    const state = useOverlayTriggerState({});

    const [wasOpen, setWasOpen] = React.useState(false);

    const modalRef = React.useRef(null);
    const { modalProps, underlayProps } = useModalOverlay(
      { isDismissable, isKeyboardDismissDisabled },
      state,
      modalRef
    );

    useEffect(() => {
      // Make sure to call the onClose and onOpen callbacks only once when the modal is opened or closed and not on initial render
      if (!state.isOpen && wasOpen) {
        onClose?.();
      } else if (state.isOpen && !wasOpen) {
        onOpen?.();
      }
      setWasOpen(state.isOpen);
    }, [state.isOpen]);

    // Expose imperative methods to parent component to open and close the modal
    useImperativeHandle(ref, () => ({
      open: () => {
        state.open();
      },
      close: () => {
        state.close();
      }
    }));

    return (
      <>
        {state.isOpen && (
          <Overlay>
            <div
              className={classnames(
                'fixed z-50 top-0 right-0 bottom-0 left-0',
                'bg-black bg-opacity-50',
                'flex items-center justify-center'
              )}
              {...underlayProps}
            >
              <div className="rounded-xl shadow-lg bg-white relative" {...modalProps} ref={modalRef}>
                <DismissButton onDismiss={state.close} />

                {onRenderHeader && (
                  <>
                    <div className="flex px-6 pt-4 pb-2">
                      <div className="text-base font-bold pt-2">{onRenderHeader()}</div>
                      <div
                        className="cursor-pointer ml-auto"
                        onClick={() => {
                          state.close();
                          onClose?.();
                        }}
                      >
                        <Icon type="close" size="large" />
                      </div>
                    </div>
                    {showHeaderBorder && <hr className="mx-4 mt-1" />}
                  </>
                )}

                <div className="p-6 text-sm text-darkBlue-60 font-normal">{children}</div>

                {onRenderFooter && (
                  <>
                    <div className="px-6 py-3">{onRenderFooter()}</div>
                  </>
                )}
              </div>
            </div>
          </Overlay>
        )}
      </>
    );
  }
);
