import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { HELP_CENTER, SUBMIT_REQUEST } from '@/constants';

import { SimpleFooter } from './SimpleFooter';
import logo from '@/assets/logoWhite.svg';
import truck from '@/assets/truck.svg';

const FooterTextBlock: React.FC<React.PropsWithChildren<{ title: React.ReactNode }>> = ({ title, children }) => (
  <div className="space-y-2">
    <div className="font-bold">{title}</div>

    {children}
  </div>
);

export const Footer: React.FC = () => (
  <>
    <div className="z-10 flex mt-auto bg-darkBlue text-darkBlue-20">
      <div className="py-10 flex items-center gap-8 md:gap-20 w-full ml-4 md:ml-20 mr-auto text-sm max-w-7xl min-w-[663px]">
        <img src={logo} className="h-4 md:h-7" />

        <FooterTextBlock
          title={<FormattedMessage id={translations.application.footer.bigFooter.technicalSupport.title} />}
        >
          <div>
            <FormattedMessage
              id={translations.application.footer.bigFooter.technicalSupport.helpCenter}
              values={{
                a: ((text: string) => (
                  <Link to={HELP_CENTER} target="_blank" className="underline">
                    {text}
                  </Link>
                )) as unknown as React.ReactNode
              }}
            />
          </div>

          <div>
            <FormattedMessage
              id={translations.application.footer.bigFooter.technicalSupport.submitRequest}
              values={{
                a: ((text: string) => (
                  <Link to={SUBMIT_REQUEST} target="_blank" className="underline">
                    {text}
                  </Link>
                )) as unknown as React.ReactNode
              }}
            />
          </div>
        </FooterTextBlock>
      </div>

      <img src={truck} className="hidden h-40 my-4 mr-20 md:flex" />
    </div>

    <SimpleFooter />
  </>
);
