import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';

import { PrinterProfileGroups } from './PrinterProfileGroups';

interface PrinterProfileGroupsSelectorProps
  extends Omit<React.ComponentProps<typeof PrinterProfileGroups>, 'savedPrinterGroups'> {
  name: string;
  control: Control<FieldValues>;
}

export const PrinterProfileGroupsSelector: React.FC<PrinterProfileGroupsSelectorProps> = ({
  name,
  control,
  ...rest
}) => {
  const { field } = useController({
    name,
    control
  });

  return <PrinterProfileGroups onChange={field.onChange} savedPrinterGroups={field.value} {...{ ...rest, name }} />;
};
