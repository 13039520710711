import { SelectorPrinterGroup } from '../../components/PrinterProfileGroups/types';

export interface ProfileInGroup {
  name: string;
  priority: number;
}

export interface PrinterProfileGroup {
  id: number;
  name: string;
  profiles: Array<{ name: string; priority: number }>;
  associated?: boolean;
}

export interface PrinterProfile {
  id: number;
  name: string;
  printerId?: number;
  clientId?: number;
  printerName?: string;
  created?: string;
  changed?: string;
  format?: PrintFormat;
  groups?: PrinterProfileGroup[];
  media?: string;
  active?: boolean;
  offsetX?: number;
  offsetY?: number;
  layouts?: string[];
  options?: Array<{
    key: string;
    value: string;
  }>;
}

export interface InitialPrinterProfileFormData {
  printerId: string[];
  clientId: string[];
  profileName: string;
  format?: PrintFormat;
  groups: SelectorPrinterGroup[];
  media?: string[];
  active?: boolean;
  offsetX?: number;
  offsetY?: number;
  layouts?: string;
  printerOptions?: FormPrinterOptions;
}

export enum PrinterProfileStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum PrintFormat {
  PDF = 'PDF',
  ZPL = 'ZPL'
}

export const printFormat = [
  { id: PrintFormat.PDF, name: PrintFormat.PDF },
  { id: PrintFormat.ZPL, name: PrintFormat.ZPL }
];

export enum PrinterProfileDialogs {
  Delete = 'DELETE'
}

export interface PrinterProfileGroupsResponse {
  groups: Array<PrinterGroupInGroupsResponse>;
}
export interface PrinterGroupInGroupsResponse {
  id: number;
  groupName: string;
  printerProfileAndPriorities: Array<ProfileInGroup>;
}

export type FormPrinterOptions = { [key: string]: string };

export interface PrinterProfileFormData {
  printerId: string[];
  active: boolean;
  profileName: string;
  groups: ProfileInGroup[];
  format: PrintFormat;
  media: string;
  offsetX?: number;
  offsetY?: number;
  layouts?: string;
  printerOptions: FormPrinterOptions;
}

export interface PrinterProfileCreateRequestBody {
  printerId?: number;
  active: boolean;
  name: string;
  printerGroups: ProfileInGroup[];
  printerRender: {
    format: PrintFormat;
    media: string;
    offsetX?: number;
    offsetY?: number;
    layouts?: string[];
    options?: PrinterOptionsInRequest[];
  };
}

export interface Printer {
  printerId: number;
  clientId: number;
  name: string;
  medias?: string[];
  options?: Array<PrinterOption>;
}

export enum PrinterOptionKey {
  Bins = 'bins',
  Collate = 'collate',
  Color = 'color',
  Dpi = 'dpi',
  Duplex = 'duplex',
  Nup = 'nup'
}

export interface PrinterOption {
  key: PrinterOptionKey;
  defaultValue: boolean | string | number | null;
  values: Array<string | boolean | number>;
}

export interface PrinterOptionsInRequest {
  key: PrinterOptionKey;
  value: string;
}
