import React from 'react';
import classnames from 'classnames';

import { Is, IsComponent, IsProps } from '@nshift/common/components/Is';
import { Icon, Type } from './Icon/Icon';
import { isNil } from 'lodash';
import { NavLink } from 'react-router-dom';
import { LoadingSize, Spinner, SpinnerColor } from './Loading';

export type Appearance = 'none' | 'primary' | 'secondary' | 'tertiary' | 'context';

export type Size = 'xsmall' | 'small' | 'medium' | 'large';

const appearanceStyles: Record<Appearance, string> = {
  none: 'hover:bg-internationalBlue-5 text-internationalBlue',
  primary:
    'bg-highlightingBlue active:bg-darkBlue text-white disabled:bg-internationalBlue-5 disabled:text-darkBlue-50',
  secondary:
    'bg-internationalBlue-10 active:bg-darkBlue active:text-white text-highlightingBlue disabled:bg-internationalBlue-5 disabled:text-darkBlue-50',
  tertiary:
    'bg-transparent text-highlightingBlue border border-highlightingBlue  disabled:bg-internationalBlue-5 disabled:text-darkBlue-50 active:bg-darkBlue active:text-white hover:bg-highlightingBlue-20',
  context: 'bg-highlightingBlue hover:bg-internationalBlue-10 text-white disabled:bg-internationalBlue-10'
};

const height: Record<Size, string> = {
  xsmall: 'h-6',
  small: 'h-8',
  medium: 'h-10',
  large: 'h-12'
};

const fontSize: Record<Size, string> = {
  xsmall: 'text-sm',
  small: 'text-sm',
  medium: 'text-base',
  large: 'text-lg'
};

export interface ButtonProps {
  appearance?: Appearance;
  size?: Size;
  icon?: Type;
  startIcon?: boolean;
  endIcon?: boolean;
  isLoading?: boolean;
}

export const Button = React.forwardRef(
  <T extends IsComponent>(
    {
      //@ts-ignore
      is = 'button',
      appearance = 'none',
      size = 'medium',
      disabled,
      className,
      children,
      onClick,
      icon,
      startIcon = false,
      endIcon = false,
      linkTo,
      isLoading = false,
      ...rest
    }: ButtonProps & IsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => {
    const [clicking, setClicking] = React.useState(false);

    const ButtonIcon = (
      <Icon
        type={icon}
        appearance={appearance === 'primary' || clicking ? 'white' : 'highlightingBlue'}
        className={classnames({ 'mr-1': startIcon, 'ml-1': endIcon })}
        sizeClassName="text-[18px]"
        iconColor={disabled ? 'text-darkBlue-50' : ''}
      />
    );

    const buttonContent = (
      <>
        {!isNil(icon) && startIcon && ButtonIcon}
        {children}
        {!isNil(icon) && endIcon && ButtonIcon}
      </>
    );

    const classNameString = classnames(
      'group relative inline-flex items-center disabled:cursor-default justify-center transition-colors cursor-pointer rounded-full box-border text-nowrap',
      height[size],
      fontSize[size],
      appearanceStyles[appearance],
      className
    );

    return linkTo ? (
      <Is {...{ ref, is, disabled }} className={classNameString} {...rest}>
        <NavLink className="content-center w-full h-full rounded-full" to={disabled ? null : linkTo}>
          {buttonContent}
        </NavLink>
      </Is>
    ) : (
      <Is
        {...{ ref, is, disabled }}
        onMouseDown={() => setClicking(true)}
        onMouseUp={() => setClicking(false)}
        className={`px-12 py-[13px] ${classNameString}`}
        onClick={disabled ? null : onClick}
        {...rest}
      >
        {isLoading && <Spinner isIndeterminate size={LoadingSize.Small} fillColor={SpinnerColor.Disabled} />}
        {!isLoading && buttonContent}
      </Is>
    );
  }
);
