import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';

import { translations } from '@/locales';
import { getClientList } from '@/services/api/clients';
import { useFilterManager } from '@/hooks/useFilterManager';

import { TextField } from '@nshift/common/components/Input';

import { FilterField } from '@/components/App/Filters';
import { FilterSection } from '@/components/App/Filters/FilterSection';
import { ClientStatusSelect } from '@/components/App/Selects/Client/ClientStatusSelect';
import { ClientControlOptionSelect } from '@/components/App/Selects/Client/ClientControlOptionSelect';

import { clientFilterSelectors } from './constants';
import { ClientFilters } from './types';

export interface Props {
  initialFilterState: ClientFilters;
  defaultFilterState: ClientFilters;
  onApplyFilters: (filters: ClientFilters) => void;
}

export const ClientFilterSection: FC<Props> = ({ initialFilterState, defaultFilterState, onApplyFilters }) => {
  const intl = useIntl();
  const { criteria, updateCriteria, bulkUpdateCriteria } = useFilterManager<ClientFilters>(defaultFilterState);
  const { data: { clients = [] } = {}, isLoading: isLoadingClients } = useQuery('clients', () => getClientList());

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  return (
    <FilterSection
      selectedFilters={selectedFilters}
      fixedFilters={[]}
      filterSelectorItems={clientFilterSelectors(intl)}
      onChangeFilters={(filters) => {
        selectedFilters
          .filter((f: string) => !filters.includes(f))
          .forEach((f: string) => {
            updateCriteria(f, defaultFilterState[f as keyof ClientFilters]);
          });
        setSelectedFilters(filters);
      }}
      onApplyFilters={() => onApplyFilters(criteria)}
      onClearFilters={() => bulkUpdateCriteria(initialFilterState)}
    >
      {/* Only show filters that depend on API values when they are fetched */}
      {!isLoadingClients && clients?.length >= 0 && (
        <React.Fragment>
          <FilterField isVisible={selectedFilters.includes('name')}>
            <TextField
              label={intl.formatMessage({ id: translations.pages.clientSetup.columnHeaders.name })}
              value={criteria.name}
              onChange={(event: any) => updateCriteria('name', event.target.value)}
            />
          </FilterField>

          <FilterField isVisible={selectedFilters.includes('id')}>
            <TextField
              value={criteria.id}
              onChange={(event: any) => updateCriteria('id', event.target.value)}
              label={intl.formatMessage({ id: translations.pages.clientSetup.columnHeaders.id })}
            />
          </FilterField>

          <FilterField isVisible={selectedFilters.includes('ip')}>
            <TextField
              value={criteria.ip}
              onChange={(event: any) => updateCriteria('ip', event.target.value)}
              label={intl.formatMessage({ id: translations.pages.clientSetup.ip })}
            />
          </FilterField>
        </React.Fragment>
      )}

      <FilterField isVisible={selectedFilters.includes('statuses')}>
        <ClientStatusSelect
          value={criteria.statuses}
          onChange={(value: string[]) => updateCriteria('statuses', value)}
        />
      </FilterField>

      <FilterField isVisible={selectedFilters.includes('blocked')}>
        <ClientControlOptionSelect
          value={criteria.blocked}
          onChange={(value: boolean | null) => updateCriteria('blocked', value)}
        />
      </FilterField>
    </FilterSection>
  );
};
