import React from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { useNumberFieldState } from 'react-stately';
import { AriaNumberFieldProps, useLocale, useNumberField } from 'react-aria';

import { textInputStyle, labelStyle } from '@nshift/common/components/Select/TextInput';

import { Size, sizeToStyleMap } from './constants';

import { Icon } from '../Icon';
import { RequiredTag } from '../RequiredTag';

export interface NumberFieldProps extends AriaNumberFieldProps {
  size?: Size;
  showButtons?: boolean;
  description?: string;
  isDisabled?: boolean;
  name?: string;
  className?: string;
  inputClassName?: string;
  required?: boolean;
}

export const NumberField: React.FC<NumberFieldProps> = ({
  size = Size.Medium,
  label,
  description,
  showButtons = true,
  isDisabled,
  name,
  className,
  inputClassName,
  required,
  ...props
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { locale } = useLocale();
  const state = useNumberFieldState({ isDisabled, ...props, locale });
  const [isFocused, setIsFocused] = React.useState(false);

  const {
    labelProps,
    groupProps,
    inputProps,
    descriptionProps,
    incrementButtonProps,
    decrementButtonProps,
    errorMessageProps,
    isInvalid,
    validationErrors
  } = useNumberField({ isDisabled, label, description, ...props }, state, inputRef);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    props.onFocus?.(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    props.onBlur?.(event);
    let value = state.inputValue.replace(/,/g, '').replace(/^0+(?!$)/, '') || '0';
    let numberValue = parseInt(value, 10);

    if (value === '-0') {
      numberValue = 0;
      value = '0';
    }

    if (numberValue > props.maxValue) {
      state.setNumberValue(props.maxValue);
      state.setInputValue(props.maxValue.toString());
      return;
    }

    if (numberValue < props.minValue) {
      state.setNumberValue(props.minValue);
      state.setInputValue(props.minValue.toString());
      return;
    }

    state.setNumberValue(numberValue);
    state.setInputValue(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/,/g, '');
    if (/^\+?-?\d*$/.test(value)) {
      if (value.startsWith('+')) value = value.slice(1);
      const numberValue = parseInt(value, 10);
      state.setNumberValue(
        value === '-' || isNaN(numberValue)
          ? NaN
          : Math.max(props.minValue ?? numberValue, Math.min(numberValue, props.maxValue ?? numberValue))
      );
      state.setInputValue(value === '-0' ? '0' : value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = event;
    const value = (target as HTMLInputElement).value;
    switch (key) {
      case '-':
        state.setInputValue('-' + value.replace('-', ''));
        break;
      case '+':
        state.setInputValue(value.replace('-', ''));
        break;
      case 'ArrowUp':
        handleIncrement();
        break;
      case 'ArrowDown':
        handleDecrement();
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
        // Allow left and right arrow keys for navigation
        break;
      default:
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete') {
          event.preventDefault();
        } else if (/^\d$/.test(key)) {
          const newValue = key;
          const numberValue = parseInt(newValue, 10);
          if (
            !isNaN(numberValue) &&
            ((props.minValue !== undefined && numberValue < props.minValue) ||
              (props.maxValue !== undefined && numberValue > props.maxValue))
          ) {
            event.preventDefault();
          }
        }
        break;
    }
  };

  const adjustValue = (
    adjustFn: () => void,
    limit: number | undefined,
    comparator: (a: number, b: number) => boolean
  ): void => {
    adjustFn();
    if (limit !== undefined && comparator(state.numberValue, limit)) {
      state.setNumberValue(limit);
    }
  };

  const handleIncrement = (): void => adjustValue(state.increment, props.maxValue, (a, b) => a > b);
  const handleDecrement = (): void => adjustValue(state.decrement, props.minValue, (a, b) => a < b);

  return (
    <div className={classNames('relative w-fit group', className)}>
      <label
        {...labelProps}
        className={labelStyle({
          focused: isFocused || !isNil(state.numberValue),
          invalid: !isNil(validationErrors),
          disabled: isDisabled
        })}
      >
        {label}
        {required && <RequiredTag />}
      </label>

      <div {...groupProps} className={classNames('relative flex w-fit', className)}>
        <input
          {...inputProps}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          className={textInputStyle({ withTrailingIcon: true, className: classNames('pr-9') })}
          pattern="^-?\d*$"
        />

        {showButtons && (
          <div className="absolute flex flex-col justify-center h-full right-3">
            <a
              {...incrementButtonProps}
              className={classNames('flex', isDisabled ? 'cursor-not-allowed' : 'cursor-pointer', 'h-fit')}
              onClick={handleIncrement}
            >
              <Icon
                type="chevronUp"
                sizeClassName={size === Size.Small ? 'text-[10px] mb-1' : 'text-xs'}
                className={classNames('transition-transform', !isDisabled && 'hover:scale-110 active:scale-100')}
              />
            </a>

            <a
              {...decrementButtonProps}
              className={classNames('flex', isDisabled ? 'cursor-not-allowed' : 'cursor-pointer', 'h-fit')}
              onClick={handleDecrement}
            >
              <Icon
                type="chevronDown"
                sizeClassName={size === Size.Small ? 'text-[10px]' : 'text-xs'}
                className={classNames('transition-transform', !isDisabled && 'hover:scale-110 active:scale-100')}
              />
            </a>
          </div>
        )}
      </div>

      {description && (
        <legend
          {...descriptionProps}
          className={classNames(
            'mt-2',
            isInvalid || validationErrors.length > 0 ? 'text-warningRed' : 'text-darkBlue-60',
            isDisabled && '!text-darkBlue-20',
            sizeToStyleMap[size].descriptionStyle
          )}
        >
          {description}
        </legend>
      )}

      {isInvalid && (
        <legend
          {...errorMessageProps}
          className={classNames('text-warningRed mt-2', sizeToStyleMap[size].descriptionStyle)}
        >
          {validationErrors.join(' ')}
        </legend>
      )}
    </div>
  );
};
