import React, { useState, useCallback } from 'react';

export const useThrottledRefetch = (refetch: () => void, delay: number = 5000) => {
  const [canRefetch, setCanRefetch] = useState(true);

  const throttledRefetch = useCallback(() => {
    if (!canRefetch) return;

    refetch();
    setCanRefetch(false);

    setTimeout(() => {
      setCanRefetch(true);
    }, delay);
  }, [canRefetch, refetch, delay]);

  return { throttledRefetch, canRefetch };
};
