import { PrinterOption, PrinterOptionKey } from './types';

export const mockPrinterOptions: PrinterOption[] = [
  {
    key: PrinterOptionKey.Bins,
    defaultValue: null,
    values: []
  },
  {
    key: PrinterOptionKey.Collate,
    defaultValue: true,
    values: [true, false]
  },
  {
    key: PrinterOptionKey.Color,
    defaultValue: true,
    values: [true, false]
  },
  {
    key: PrinterOptionKey.Dpi,
    defaultValue: null,
    values: []
  },
  {
    key: PrinterOptionKey.Duplex,
    defaultValue: true,
    values: [true, false]
  },
  {
    key: PrinterOptionKey.Nup,
    defaultValue: 1,
    values: []
  }
];

export const acceptedMediaTypes = [
  'laser-1a5',
  'laser-2a5',
  'laser-2x104x190top',
  'laser-2x104x250',
  'laser-2x104x250rot',
  'laser-2x110x190',
  'laser-2x210x104',
  'laser-3part',
  'laser-a4',
  'laser-a5',
  'laser-c5',
  'thermo-105',
  'thermo-148',
  'thermo-165',
  'thermo-190',
  'thermo-225',
  'thermo-250',
  'thermo-285',
  'thermo-55',
  'thermo-72'
];
