import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';

import { type OmitableFieldTypes } from '@nshift/common/types/ommitableFieldTypes';

import { BaseCombobox, BaseComboBoxProps } from './BaseCombobox';

interface FormComboboxProps extends Omit<BaseComboBoxProps, OmitableFieldTypes> {
  control: Control<FieldValues>;
}

export const FormCombobox: React.FC<FormComboboxProps> = ({ name, control, ...rest }) => {
  const { field, fieldState } = useController({ name, control });

  return <BaseCombobox error={fieldState.error} isInvalid={fieldState.invalid} {...rest} {...field} />;
};
