import React, { FC } from 'react';
import { translations } from '@/locales';
import { FormattedMessage, useIntl } from 'react-intl';

import { dateFormatter } from '@nshift/common/constants/dateTime';
import { Badge } from '@nshift/common/components/Badge';

import { PrinterProfileStatusBadge } from './PrinterProfileStatusBadge';
import { getStatus } from './PrinterProfilesTableConfig';

const DrawerDataRow: FC<{ label: React.ReactNode; value?: any }> = ({ label, value }) => (
  <div className="flex items-center justify-between">
    <div className="font-medium min-w-fit text-darkBlue-60">{label}:</div>

    <div className="w-full text-right break-all">{value ?? '-'}</div>
  </div>
);

export const PrinterProfileDetailsView: FC<{ row: any }> = ({ row }) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col">
      <div className="mb-8 text-lg font-semibold text-darkBlue">
        <FormattedMessage id={translations.pages.printerProfiles.details.title} />
      </div>

      <div className="flex flex-col gap-4 text-sm">
        <DrawerDataRow
          label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.id })}
          value={row.id}
        />

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.profileName })}
          value={row.name}
        />

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.status })}
          value={<PrinterProfileStatusBadge className="ml-auto w-fit" status={getStatus(row)} />}
        />

        <div className="flex flex-wrap items-center gap-1">
          <div className="font-medium text-darkBlue-60 min-w-fit">
            <FormattedMessage id={translations.domains.commonColumnsFilters.profileGroups} />:
          </div>

          {row.printerProfileGroups?.length > 0 ? (
            row.printerProfileGroups?.map(({ id, name }: { [key: string]: string }) => (
              <Badge clickable={false} key={id} appearance="lightBlue" className="flex items-center text-xs">
                <span className="break-all">{name}</span>
              </Badge>
            ))
          ) : (
            <div className="ml-auto">-</div>
          )}
        </div>

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.client })}
          value={row.clientName}
        />

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.printer })}
          value={row.printerName}
        />

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.printerId })}
          value={row.printerId}
        />

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.media })}
          value={row.media}
        />

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.format })}
          value={row.format}
        />

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.created })}
          value={dateFormatter(row.created)}
        />

        <DrawerDataRow
          label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.changed })}
          value={dateFormatter(row.updated)}
        />
      </div>
    </div>
  );
};
