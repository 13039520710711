import React from 'react';
import classnames from 'classnames';
import { tv } from 'tailwind-variants';

import icon from './style.module.css';

export type Type = keyof typeof icon;

export type IconAppearance = 'none' | 'blue' | 'highlightingBlue' | 'green' | 'yellow' | 'red' | 'pink' | 'white';

export type Size = 'xsmall' | 'small' | 'medium' | 'large';

const style = tv({
  base: 'align-middle inline-flex text-center transition-all',
  variants: {
    color: {
      'none-light': 'text-darkBlue-40',
      'none-regular': 'text-darkBlue-80',
      'blue-light': 'text-internationalBlue-30',
      'blue-regular': 'text-internationalBlue',
      'highlightingBlue-light': 'text-highlightingBlue-30',
      'highlightingBlue-regular': 'text-highlightingBlue',
      'green-light': 'text-confirmingGreen-30',
      'green-regular': 'text-confirmingGreen',
      'yellow-light': 'text-alertingYellow-30',
      'yellow-regular': 'text-alertingYellow',
      'red-light': 'text-warningRed-30',
      'red-regular': 'text-warningRed',
      'pink-light': 'text-pink-30',
      'pink-regular': 'text-pink',
      'white-light': 'text-white',
      'white-regular': 'text-white'
    },
    size: {
      xsmall: 'text-base leading-4',
      small: 'text-xl leading-5',
      medium: 'text-2xl leading-6',
      large: 'text-[32px] leading-8'
    }
  }
});

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type: Type;
  appearance?: IconAppearance;
  light?: boolean;
  className?: string;
  size?: Size;
  sizeClassName?: string;
  iconColor?: string;
}

export const Icon: React.FC<Props> = ({
  appearance = 'none',
  className,
  size = 'medium',
  sizeClassName,
  light = false,
  type,
  ...rest
}) => {
  return (
    <i
      className={classnames(
        style({ size, color: `${appearance}-${light ? 'light' : 'regular'}`, className: sizeClassName }),
        icon[type],
        className
      )}
      {...rest}
    />
  );
};
