import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { Item, useOverlayTriggerState } from 'react-stately';
import { useButton, useOverlayTrigger, usePreventScroll } from 'react-aria';

import { Icon } from '@nshift/common/components/Icon';
import { TextField } from '@nshift/common/components/Input';
import { Popover } from '@nshift/common/components/Modal/Popover';

import { GridList } from './PrinterProfileGridList';

// This component is quite complicated and only used here so it shouldn't be in Common. We have other comboboxes now and we will build on top of those
export interface ComboBoxProps {
  label?: string;
  name: string;
  selectedKeys?: any[];
  items?: any[];
  addButtonLabel?: string;
  onSelectionChange?: Dispatch<SetStateAction<Set<any>>>;
  allowCustomValues?: boolean;
  selectionMode?: 'single' | 'multiple';
  onAddClick?: (key: string) => void;
  required?: boolean;
  className?: string;
}

interface AddButtonProps {
  onPress: () => void;
  label: string;
  inputFieldValue: string;
}

const AddButton: React.FC<AddButtonProps> = ({ onPress, label, inputFieldValue }) => {
  const ref = React.useRef(null);
  const { buttonProps } = useButton({ onPress }, ref);

  return (
    <div className="px-2 pt-2 -mb-2">
      <button
        {...buttonProps}
        ref={ref}
        className="flex items-center justify-start w-full h-8 rounded text-darkBlue-70 hover:bg-highlightingBlue-5"
      >
        <Icon type="add" size="small" className="mx-2" appearance="blue" />

        <span className="mr-1 font-bold">{label}</span>
        {inputFieldValue}
      </button>
    </div>
  );
};

export const ComboBox: React.FC<ComboBoxProps> = ({
  label,
  selectedKeys,
  items,
  addButtonLabel = 'Add',
  allowCustomValues,
  selectionMode = 'single',
  onSelectionChange,
  onAddClick,
  required,
  name,
  className,
  ...props
}) => {
  const inputRef = React.useRef(null);
  const parentRef = React.useRef(null);
  const gridListRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const state = useOverlayTriggerState(props);
  const preventDefault = usePreventScroll();

  const [inputFieldValue, setInputFieldValue] = React.useState('');
  const [filteredItems, setFilteredItems] = React.useState(items);

  // Make sure that the dropdown has the same width as the input field.
  useEffect(() => {
    if (state.isOpen && gridListRef) {
      // @ts-ignore
      const inputWidth = inputRef.current.getBoundingClientRect().width;
      // @ts-ignore
      gridListRef.current.style.minWidth = `${inputWidth}px`;
    }

    items && items.length > 0 && (inputFieldValue.length > 0 ? filterItems(inputFieldValue) : setFilteredItems(items));
  }, [parentRef, state.isOpen, items]);

  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'menu' }, state, popoverRef);

  const filterItems = (filterValue: string) => {
    setFilteredItems(filterValue.length === 0 ? items : items?.filter((item) => item.name?.includes(filterValue)));
  };

  const debouncedFiltering = debounce((value) => filterItems(value), 300);

  const shouldShowAddNewButton = () =>
    allowCustomValues && !items?.some((item) => item.name === inputFieldValue) && inputFieldValue.trim().length > 0;

  const handleInputChange = (event: any) => {
    setInputFieldValue(event.target.value);
    debouncedFiltering(event.target.value);
    preventDefault;
    state.open();
  };

  const onSelection = (key: SetStateAction<any>) => {
    if (selectionMode === 'single') {
      state.close();
    }
    onSelectionChange?.(key);
  };

  const handleAddClick = () => {
    onAddClick?.(inputFieldValue);
    state.close();
    clearInputText();
  };

  const clearInputText = () => {
    setInputFieldValue('');
  };

  const handleOpen = () => {
    preventDefault;
    state.open();
  };

  return (
    <div className={classNames('inline-flex flex-col', className)}>
      <div className="flex flex-col w-full" ref={parentRef}>
        <TextField
          {...triggerProps}
          ref={inputRef}
          id="combobox-input"
          name={name}
          label={label}
          onFocus={handleOpen}
          value={inputFieldValue}
          onChange={handleInputChange}
          onClick={handleOpen}
          required={required}
          className="w-full"
          inputClassName="w-full"
        />
        {state.isOpen && (
          <Popover
            {...overlayProps}
            triggerRef={inputRef}
            popoverRef={popoverRef}
            state={state}
            className="z-10 mt-1 bg-white rounded-md shadow-lg border-darkBlue-5"
          >
            {shouldShowAddNewButton() && (
              <AddButton label={addButtonLabel} inputFieldValue={inputFieldValue} onPress={handleAddClick} />
            )}

            <GridList
              ref={gridListRef}
              items={filteredItems || []}
              selectionMode={selectionMode}
              selectedKeys={selectedKeys}
              onSelectionChange={onSelection}
            >
              {/* @ts-ignore */}
              {filteredItems?.map((item) => <Item key={item.id}>{item.name}</Item>)}
            </GridList>
          </Popover>
        )}
      </div>
    </div>
  );
};
