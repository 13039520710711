import React from 'react';

import { translations } from '@/locales';
import { useIntl } from 'react-intl';
import { ControlOption } from '@/domains';
import { BaseSelect, BaseSelectProps } from '@nshift/common/components/Select/BaseSelect';

export interface Props extends Omit<BaseSelectProps, 'items'> {
  value?: boolean | null;
  onChange?: (value: boolean | null) => void;
}

export const ControlOptionToValueMap: Record<ControlOption, any> = {
  [ControlOption.All]: null,
  [ControlOption.Blocked]: true,
  [ControlOption.Unblocked]: false
};

const stringToBoolean = (value: string): boolean | null => {
  switch (value) {
    case 'null':
      return null;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

export const controlOptionsToNameMap: Record<ControlOption, string> = {
  [ControlOption.All]: translations.domains.clientControlOptions.ALL,
  [ControlOption.Blocked]: translations.domains.clientControlOptions.BLOCKED,
  [ControlOption.Unblocked]: translations.domains.clientControlOptions.UNBLOCKED
};

export const ClientControlOptionSelect: React.FC<Props> = ({ value, onChange, ...props }) => {
  const intl = useIntl();

  const controlOptions = Object.values(ControlOption).map((option) => ({
    id: String(ControlOptionToValueMap[option]),
    value: intl.formatMessage({ id: controlOptionsToNameMap[option] })
  }));

  return (
    <div>
      <BaseSelect
        {...props}
        value={String(value) ?? 'null'}
        onChange={(value) => {
          console.log('value', value);
          const booleanValue = stringToBoolean(String(value));
          console.log('lala', booleanValue);
          onChange?.(booleanValue);
        }}
        items={controlOptions}
        label={intl.formatMessage({ id: translations.pages.clientSetup.blocked })}
        className="w-[150px]"
      />
    </div>
  );
};
