import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast/headless';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import React, { FC, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { DEFAULT_NULL_VALUE, urls } from '@/constants';
import { translations } from '@/locales';
import {
  getClientList,
  getPrinterProfileGroups,
  getPrinterList,
  createPrinterProfile,
  updatePrinterProfile
} from '@/services/api';
import { printerProfileSchema } from '@/schemas/PrinterProfile';

import { Icon } from '@nshift/common/components/Icon';
import { Tooltip } from '@nshift/common/components/Tooltip';
import { Button } from '@nshift/common/components/NewButton';
import { FormToggle } from '@nshift/common/components/FormToggle';
import { FormNumberField } from '@nshift/common/components/Input';
import { FormCombobox, FormSelect } from '@nshift/common/components/Select';
import { FormTextInput } from '@nshift/common/components/Input/FormTextInput';

import { useFormUnsavedChangesBlocker, useThrottledRefetch } from '@nshift/common/hooks';

import { ConfirmDialog } from '@/components/Dialogs';
import customResolver from '@/components/CustomResolver';
import { FormComboboxWithProblematicChoices } from '@/components/ComboboxWithProblematicChoices';
import { PrinterProfileGroupsSelector } from '@/components/PrinterProfileGroups/PrinterProfileGroupsSelector';

import { PrinterProfileFormData, printFormat } from './types';
import { acceptedMediaTypes } from './constants';

const RefreshRequestButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const intl = useIntl();
  return (
    <div className="flex items-center h-10 mb-auto">
      <Tooltip delay={0} tooltip={intl.formatMessage({ id: translations.miscellaneous.refreshPrinters })}>
        <div
          onClick={onClick}
          className="flex items-center justify-center h-8 ml-2 rounded-full text-darkBlue-60 bg-highlightingBlue-5 hover:bg-highlightingBlue-10 active:bg-highlightingBlue-30 min-w-8"
        >
          <Icon appearance="blue" type="refresh" size="small" />
        </div>
      </Tooltip>
    </div>
  );
};

interface PrinterProfileFormProps {
  profileId?: string;
  defaultData?: any;
  key: any;
  editMode?: boolean;
}

export const PrinterProfileForm: FC<PrinterProfileFormProps> = ({ profileId, defaultData, key, editMode }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { showBoundary } = useErrorBoundary();

  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    resetField
  } = useForm({
    defaultValues: defaultData,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: customResolver(printerProfileSchema)
  });

  const blocker = useFormUnsavedChangesBlocker(isDirty && !isSubmitting);

  const [profileName, client, media] = useWatch({
    control,
    name: ['profileName', 'clientId', 'media']
  });
  const printerId = useWatch({ control, name: 'printerId' });

  const [errorMessage, setErrorMessage] = useState<string>();

  const { data: clients, isLoading: clientsLoading } = useQuery(['get-clients'], () =>
    getClientList()
      .then((data) => data.clients.map(({ clientId, name }) => ({ id: clientId.toString(), value: name })))
      .catch((error) => {
        showBoundary(error);
      })
  );

  const {
    data: printers,
    isLoading: printersLoading,
    refetch: printersRefetch
  } = useQuery(['get-printers'], () => getPrinterList().then((data) => data.printers), {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const { throttledRefetch } = useThrottledRefetch(printersRefetch, 10000);

  const filteredPrinters = useMemo(() => {
    const list =
      client?.length > 0 ? printers?.filter((printer) => client?.includes(String(printer.clientId))) : printers;
    return list?.map(({ printerId, name }) => ({ id: printerId.toString(), value: name })) ?? [];
  }, [printers, client]);

  const handleError = (error: any) => {
    setErrorMessage(error.response?.data?.message);
  };

  const handleProfileCreate = (data: PrinterProfileFormData) =>
    createPrinterProfile(data)
      .then(() => {
        navigate(urls.printers.profiles.base);
        toast.success(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.createSuccess }));
      })
      .catch(handleError);

  const handleProfileUpdate = (data: PrinterProfileFormData) =>
    updatePrinterProfile(Number(profileId), data)
      .then(() => {
        navigate(urls.printers.profiles.base);
        toast.success(intl.formatMessage({ id: translations.pages.printerProfiles.toasts.updateSuccess }));
      })
      .catch(handleError);

  const onSubmit = handleSubmit((data) => {
    setErrorMessage(undefined);

    return editMode ? handleProfileUpdate(data) : handleProfileCreate(data);
  });

  const medias = useMemo(() => {
    return (
      printers
        ?.find((printer) => printerId?.includes(printer.printerId.toString()))
        ?.medias?.map((media) => ({ id: media, value: media })) ?? []
    );
  }, [printerId, printersLoading]);

  const printerOptions = useMemo(() => {
    const options = printers?.find((printer) => printerId?.includes(printer.printerId.toString()))?.options;

    return options?.filter((option) => option.values.length > 0);
  }, [printers, printerId]);

  const { data: { groups: printerProfileGroups = [] } = {} } = useQuery(
    ['get-printer-profile-groups'],
    getPrinterProfileGroups,
    { refetchOnWindowFocus: false }
  );

  React.useEffect(() => {
    printerOptions?.forEach((option) =>
      resetField(
        `printerOptions.${option.key}`,
        editMode && printerId[0] === defaultData.printerId[0]
          ? { defaultValue: defaultData.printerOptions[option.key] }
          : { defaultValue: null }
      )
    );
  }, [printerId]);

  return (
    <div>
      <form
        className="flex flex-col gap-6"
        onSubmit={onSubmit}
        key={key}
        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      >
        <div className="px-6 py-8 space-y-6 bg-white rounded-lg shadow">
          <div className="text-xl font-semibold font-title text-internationalBlue">
            <FormattedMessage id={translations.pages.printerProfiles.sections.general} />
          </div>

          <div className="grid items-start w-full grid-cols-2 gap-4">
            <FormTextInput
              id="profileName"
              name="profileName"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.profileName })}
              inputClassName="w-full"
              className="w-full"
              isRequired
            />

            <div className="flex items-center h-10">
              <FormToggle
                name="active"
                register={register}
                label={intl.formatMessage({ id: translations.domains.printerProfilesStatusFilter.ACTIVE })}
              />
            </div>
          </div>

          <div className="grid items-start grid-cols-2 gap-y-6 gap-x-4">
            {!clientsLoading && (
              <FormCombobox
                name="clientId"
                control={control}
                label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.client })}
                items={clients ?? []}
                className="w-full"
                isRequired
              />
            )}

            {!printersLoading && (
              <div className="flex items-center w-full">
                <FormCombobox
                  items={filteredPrinters}
                  label={intl.formatMessage({ id: translations.domains.commonColumnsFilters.printer })}
                  control={control}
                  name="printerId"
                  className="w-full"
                  isRequired
                />

                <RefreshRequestButton onClick={throttledRefetch} />
              </div>
            )}

            <FormSelect
              control={control}
              name="format"
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.format })}
              items={printFormat.map(({ id, name }) => ({ id, value: name }))}
              isRequired
            />

            <FormComboboxWithProblematicChoices
              disabled={printersLoading}
              problematicItems={acceptedMediaTypes
                .filter((item) => !medias.find((m) => m.id === item))
                .map((media) => ({ id: media, value: media }))}
              items={medias}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.media })}
              control={control}
              name="media"
              warning={intl.formatMessage({ id: translations.pages.printerProfiles.problematicMediaWarning })}
              required
            />
          </div>

          <div className="grid items-start grid-cols-3 gap-x-4">
            <FormNumberField
              id="offsetX"
              name="offsetX"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.offsetX })}
              inputClassName="w-full"
              className="w-full"
              maxValue={100}
              minValue={-100}
            />

            <FormNumberField
              id="offsetY"
              name="offsetY"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.offsetY })}
              inputClassName="w-full"
              className="w-full"
              maxValue={100}
              minValue={-100}
            />

            <FormTextInput
              id="layouts"
              name="layouts"
              control={control}
              label={intl.formatMessage({ id: translations.pages.printerProfiles.columnsFilters.layouts })}
              inputClassName="w-full"
              className="w-full"
            />
          </div>
        </div>

        <div className="px-6 py-8 bg-white rounded-lg shadow">
          <div className="mb-6 text-xl font-semibold font-title text-internationalBlue">
            <FormattedMessage id={translations.pages.printerProfiles.sections.profileGroups} />
          </div>

          <PrinterProfileGroupsSelector
            name="groups"
            initialGroups={printerProfileGroups.map(({ groupName, ...rest }) => ({ name: groupName, ...rest }))}
            profileName={profileName}
            control={control}
          />
        </div>

        <div className="px-6 py-8 bg-white rounded-lg shadow">
          <div className="mb-6 text-xl font-semibold font-title text-internationalBlue">
            <FormattedMessage id={translations.pages.printerProfiles.sections.printerOptions.title} />
          </div>

          {!printerId || printerId?.length === 0 ? (
            <div className="text-sm text-darkBlue-60">
              <FormattedMessage id={translations.pages.printerProfiles.sections.printerOptions.noPrinterSelected} />
            </div>
          ) : (
            <div key={`${printerId}-options`} className="grid grid-cols-3 gap-4">
              {printerOptions?.map(({ key, values }) => (
                <FormSelect
                  key={`${printerId}-options-${key}`}
                  control={control}
                  name={`printerOptions.${key}`}
                  label={key}
                  items={[
                    // @ts-ignore
                    {
                      id: DEFAULT_NULL_VALUE,
                      value: intl.formatMessage({ id: translations.miscellaneous.select.noItem })
                    },
                    ...values.map((value) => ({ id: value.toString(), value: value.toString() }))
                  ]}
                />
              ))}
            </div>
          )}
        </div>

        <div className="text-warningRed">{errorMessage}</div>

        <div className="flex justify-end space-x-4">
          <Button onClick={() => navigate(urls.printers.profiles.base)} appearance="outlined">
            <FormattedMessage id={translations.application.buttons.cancel} />
          </Button>

          <Button
            appearance="filled"
            type="submit"
            isLoading={isSubmitting}
            isDisabled={
              !isDirty ||
              isSubmitting ||
              !isValid ||
              printerId?.length === 0 ||
              media?.length === 0 ||
              client?.length === 0
            }
          >
            <FormattedMessage id={translations.application.buttons.save} />
          </Button>
        </div>
      </form>

      <ConfirmDialog
        isOpen={blocker.isBlocked}
        title={intl.formatMessage({ id: translations.pages.printerProfiles.dialogs.unsavedChanges.title })}
        onClose={() => {
          blocker.reset?.();
        }}
        onConfirm={() => {
          blocker.proceed?.();
        }}
      >
        <FormattedMessage id={translations.pages.printerProfiles.dialogs.unsavedChanges.message} />
      </ConfirmDialog>
    </div>
  );
};
