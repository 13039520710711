import React from 'react';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { tv } from 'tailwind-variants';
import { Group, Input, Label, TextField, TextFieldProps } from 'react-aria-components';

import { RequiredLabel } from '@nshift/common/components/RequiredTag';
import { FieldError } from '@nshift/common/components/FieldError';
import { Icon, Type } from '@nshift/common/components/Icon/IconNew';

interface Props extends TextFieldProps {
  label?: string;
  value?: string;
  error?: any;
  className?: string;
  inputClassName?: string;
  leadingIcon?: Type;
  trailingIcon?: Type;
  onChange?: any;
}

export const textInputStyle = tv({
  base: [
    'flex w-full h-10 rounded text-sm text-darkBlue-80 items-center pr-10 px-3.5 outline-none transition-colors duration-200',
    'border border-darkBlue-40 focus:border-internationalBlue',
    'disabled:bg-darkBlue-5 disabled:group-hover:border-darkBlue-40 disabled:cursor-not-allowed disabled:text-darkBlue-40 disabled:group-hover:bg-darkBlue-5',
    'group-hover:border-internationalBlue group-hover:bg-internationalBlue-5',
    'invalid:border-warningRed invalid:group-hover:bg-warningRed-5 invalid:group-hover:border-warningRed invalid:focus:border-warningRed'
  ],
  variants: {
    withLeadingIcon: { true: 'pl-9' },
    withTrailingIcon: { true: 'pr-9' }
  }
});

export const labelStyle = tv({
  base: [
    'absolute z-[1] pointer-events-none h-fit max-w-[calc(100%-22px)] text-sm text-darkBlue-80 rounded',
    'text-ellipsis overflow-hidden whitespace-nowrap select-none transition-all',
    'before:absolute before:h-1/2 before:-z-10 before:left-0 before:bottom-0',
    'before:overflow-hidden before:w-full before:duration-200'
  ],
  variants: {
    focused: {
      true: 'mx-1 left-1.5 px-1',
      false: 'left-3.5'
    },
    withLeadingIcon: { true: 'ml-6' },
    disabled: {
      true: 'text-darkBlue-40 before:bg-darkBlue-5',
      false: 'before:bg-white group-hover:before:bg-internationalBlue-5 transition-all'
    },
    invalid: { true: 'group-hover:before:bg-warningRed-5' }
  },
  compoundVariants: [
    { focused: true, className: 'text-xs -top-2' },
    { focused: true, withLeadingIcon: true, className: 'text-xs -top-2 -left-3' },
    { focused: false, className: 'text-sm top-2.5' }
  ]
});

export const TextInput: React.FC<Props> = ({
  label,
  value,
  error,
  className,
  inputClassName,
  onChange,
  leadingIcon,
  trailingIcon,
  autoComplete = 'off',
  isRequired,
  ...rest
}) => (
  <TextField className={classNames('relative w-fit group', className)} {...{ ...rest, autoComplete }}>
    <Group>
      {({ isFocusWithin }) => (
        <>
          <Label
            className={classNames(
              labelStyle({
                focused: isFocusWithin || !!value,
                withLeadingIcon: !isNil(leadingIcon),
                disabled: rest.isDisabled,
                invalid: rest.isInvalid
              })
            )}
          >
            <RequiredLabel {...{ label, isRequired, isDisabled: rest.isDisabled }} />
          </Label>

          {!isNil(leadingIcon) && (
            <Icon type={leadingIcon} size="small" className="absolute z-[2] left-3 top-2.5" light={rest.isDisabled} />
          )}

          {!isNil(trailingIcon) && (
            <Icon type={trailingIcon} size="small" className="absolute z-[2] right-3 top-2.5" light={rest.isDisabled} />
          )}

          <Input
            className={textInputStyle({
              withLeadingIcon: !isNil(leadingIcon),
              withTrailingIcon: !isNil(trailingIcon),
              className: inputClassName
            })}
            onChange={onChange}
            value={value}
          />

          {!!error && <FieldError error={error} pathOverride={label} />}
        </>
      )}
    </Group>
  </TextField>
);
