import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { LanguageContainer } from '@/components/Context/LanguageContext';

import { App } from './App';
import { AppOutOfService } from '@nshift/common/components/ErrorPages/AppOutOfService';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';

const app = document.getElementById('app');

if (!app) throw new Error('No root element found');

const root = createRoot(app);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

root.render(
  <ErrorBoundary
    fallback={
      <AppOutOfService
        title="Oops, something went wrong"
        subtitle="Sorry, the application is currently out of service."
      />
    }
  >
    <LanguageContainer.Provider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </LanguageContainer.Provider>
  </ErrorBoundary>
);
