import React from 'react';
import { Control, useController } from 'react-hook-form';
import { TextField } from './TextField';

interface FormTextFieldProps extends React.ComponentProps<typeof TextField> {
  control: Control<any>;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({ name, control, required, ...rest }) => {
  const { field, fieldState } = useController({ name, control });

  return (
    <TextField
      error={fieldState.error}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      required={required}
      {...{ ...rest }}
    />
  );
};
